
import { connect, useStore, Provider } from "react-redux";
import { useEffect, useRef, useState, Fragment} from 'react'

import { renderToString, renderToStaticMarkup } from 'react-dom/server';

//import foodbanks from "../data/foodbanks.js"
import mapboxgl from 'mapbox-gl';

import ReactTooltip from "react-tooltip";


import { traffic_light_color, traffic_light_colors } from '../common.js'

import WardTooltip from './WardTooltip.js'


mapboxgl.accessToken = 'pk.eyJ1IjoiZmF0YnVkZGhhIiwiYSI6ImNpZ3o4c2JibTAwZnR2NW03YWFjd3JpdHIifQ.itQExL0tc-3pPvlsNF_ieg';


function useChange(callback, value)
{
  const stored = useRef(value);
  useEffect(()=>{
  	if(stored.current !== value){
	  	callback(stored.current);
	  	stored.current = value;
  	}
  }, [value]); 
}



function MapView({currentWard, hoveredWard, ...props})
{
	const [mapLoaded, setMapLoaded] = useState(false);

	//Reference to the map container, and mapbox instance (since mapboxgl dooesn't speak react)
	const mapContainer = useRef(0)
	const mapRef = useRef(0)
	const tooltipDummyRef = useRef(0)
	const layerRef = useRef(0)
 	const timeRef = useRef(null)

	//Reference to store, so that tooltip can be wired up to redux
	const store = useStore()

	//Update current layer and time (so that we can lazy update the map beyond the currently visible areas to improve responsiveness)
	layerRef.current = props.currentLayer
	timeRef.current = props.currentTime



	//Show tooltip function that uses the dummy ref
	function showTooltip(e, html)
	{
		var node = tooltipDummyRef.current;
		if(!node)return;

		var margin = 10;
		node.setAttribute('data-tip', html);
		node.style.position = 'absolute';
		node.style.left = (e.point.x-margin)+'px';
		node.style.top = (e.point.y-margin)+'px';
		node.style.width = (margin*2)+'px';
		node.style.height = (margin*2)+'px';
		ReactTooltip.show(node);
	}

	function hideTooltip()
	{
		ReactTooltip.hide();
	}



	//Store the showfood state, so that it is accessible from within tooltip function (not the neatest...)
	const showState = useRef(0);
	showState.current = {
		showFoodBanks: props.showFoodBanks,
		showFoodStores: props.showFoodStores,
		showFoodRestaurants: props.showFoodRestaurants
	}



	//props.showFoodBanks

	//First time only
	useEffect(()=>{

		setTimeout(()=>{

			//Create map
			const map = new mapboxgl.Map({ 
				container: mapContainer.current,
				style: 'mapbox://styles/mapbox/streets-v11',
				center: [props.mapLocation.lng, props.mapLocation.lat],
				zoom: props.mapLocation.zoom,
				minZoom: 9,
				maxZoom: 17,
				//preserveDrawingBuffer: true
			});

			//Resize
			map.resize();

			//Update map state on dragging
			map.on('moveend', ()=>{
				props.setLocationSilent({
					lng: map.getCenter().lng.toFixed(4),
					lat: map.getCenter().lat.toFixed(4),
					zoom: map.getZoom().toFixed(2)
				})
			})

			//Once loaded, set map loaded state (so other stuff can happen)
			map.on('load', function () {
				mapRef.current = map;
				initialise_map(map);
			});

			//Add the zoom control
			map.addControl(new mapboxgl.NavigationControl({
				showCompass:false,
			}), 'top-left');

			map.once('idle',function(){
			   //alert('idle'); //nice!
			})

			//When this component is deleted, remove the map
			return ()=>{
				map.remove();
			}

		}, 400);

	}, []);

	//When the location is altered (other than by this component), fly to location
	useEffect(()=>{
		if(!mapRef.current)return;
		mapRef.current.easeTo({
			center: [props.mapLocation.lat, props.mapLocation.lng],
			zoom: props.mapLocation.zoom,
			duration: 1000,
		});

	}, [props.mapLocation.updateCounter])

	function initialise_map(map)
	{
		
		//Disable green areas, so they don't conflict with map overlay colour
		map.setLayoutProperty('landuse', 'visibility', 'none');
		//map.setLayoutProperty('nationalpark', 'visibility', 'none');

		//Add havering geometry
		map.addSource('havering', {type:'geojson', data:'/data/havering_boundary.geojson'})

		//Add wards geometry
		map.addSource('wards', {type:'geojson', data:'/data/havering_ward_boundaries.geojson', promoteId:'gss_code'})

/*
		map.addSource('havering', {
			type:'vector', 
			url:"mapbox://fatbuddha.292p14u2",
			promoteId:'ctyua17cd'
		})

		map.addSource('wards', {
			type:'vector', 
			url: "mapbox://fatbuddha.4yzc48ua",
			promoteId:'wd17cd'
		})
*/



		//Add wards-points geometry (could use just wards, but this helps us place labels that don't repeat)
		map.addSource('wards-points', {type:'geojson', data:'/data/havering_ward_points.geojson', promoteId:'gss_code'})

		//Add lsoa geometry (tiled from MapBox server)
		map.addSource('lsoa', {
			type: 'vector',
      url: "mapbox://fatbuddha.5tu3r3m3",
      promoteId:'id'
		})

		//Add food stores + restaurants & takeaways
		map.addSource('food_stores_restaurants', {
			type:'geojson', 
			data:'/data/havering_food_stores_restaurants.geojson',
			cluster: true,
			clusterRadius: 50,
			clusterProperties:{
				"name_all":["concat",	["concat", ["get", "name"], "\n"]],

				"store_count":["+", ["case", ["==", ["get", "type"], "store"], 1, 0]],
				"store_names":["concat", ["case", ["==", ["get", "type"], "store"], ["concat", ["get", "name"], "\n"], ""]],

				"restaurant_count":["+", ["case", ["==", ["get", "type"], "restaurant"], 1, 0]],
				"restaurant_names":["concat", ["case", ["==", ["get", "type"], "restaurant"], ["concat", ["get", "name"], "\n"], ""]],
			}
		})

		//Add food banks
		map.addSource('food_banks', {type:'geojson', data:'/data/havering_food_banks.geojson'})

		//Add schools
		map.addSource('schools', {
			type:'geojson', 
			data:'/data/havering_schools.geojson',
			cluster: true,
			clusterRadius: 50,
			clusterProperties:{
				"name":["concat",	["concat", ["get", "name"], "\n"]],
				"eligible_pupils":["+", ["get", "eligible_pupils"]],
				"pupils":["+", ["get", "pupils"]],
			}
		})

		//Add lsoa outline layer
		map.addLayer({
			'id': 'lsoa-outline',
			'type': 'line',
			'source': 'lsoa',
			'source-layer':'lsoa',
			'layout': {},
			'paint': {
				'line-color': '#000',
				'line-opacity': 0.1,
				'line-width':1,
			}
		});

		//Add lsoa fill layer
		map.addLayer({
			'id': 'lsoa',
			'type': 'fill',
			'source': 'lsoa',
			'source-layer': 'lsoa',
			'layout': {},
			'paint': {
				'fill-color': [
					'coalesce', 
					['feature-state', 'color'], 
					'rgba(0,0,0,0)'
				],
				'fill-opacity': 0.6,
				'fill-antialias': false,
			},
		});


		//Add wards fill layer (for mouse interaction detection)
		map.addLayer({
			'id': 'wards',
			'type': 'fill',
			'source': 'wards',
			//'source-layer':'wards_boundaries',

			'layout': {},
			'paint': {
				'fill-opacity': 0,
			}
		});

		//Add wards outline layer
		map.addLayer({
			'id': 'wards-outline2',
			'type': 'line',
			'source': 'wards',
			//'source-layer':'wards_boundaries',

			'paint': {
				'line-color':'#FFF',
				'line-opacity': 1,
				'line-width': 2,
				'line-offset': [
					'case',
					['boolean', ['feature-state', 'selected'], false], 2,
					['boolean', ['feature-state', 'hovered'], false], 2,
					1
				],
			},
			'layout': {
				'line-join':'round',
			}
		});

		//Add wards outline layer
		map.addLayer({
			'id': 'wards-outline',
			'type': 'line',
			'source': 'wards',
			//'source-layer':'wards_boundaries',

			'paint': {
				'line-color': [
					'case',
					['boolean', ['feature-state', 'selected'], false], '#333',
					['boolean', ['feature-state', 'hovered'], false], '#999',
					'#AAA'
				],
				'line-opacity': 1,
				'line-width': [
					'case',
					['boolean', ['feature-state', 'selected'], false], 2,
					['boolean', ['feature-state', 'hovered'], false], 2,
					1
				],
				'line-offset': [
					'case',
					['boolean', ['feature-state', 'selected'], false], 1,
					['boolean', ['feature-state', 'hovered'], false], 1,
					0.5
				],
			},
			'layout': {
				'line-join':'round',
			}
		});


		//Add havering outline-outline layer
		map.addLayer({
			'id': 'havering-outline-background',
			'type': 'line',
			'source': 'havering',
			//'source-layer': 'la_boundaries',
			'paint': {
				//'line-color': '#F66',
				'line-color': '#FFF',
				'line-width': 3,
				'line-offset':-4,
			},
			'layout': {
				'line-join':'round',
			}
		});

		//Add havering outline layer
		map.addLayer({
			'id': 'havering-outline',
			'type': 'line',
			'source': 'havering',
			//'source-layer': 'la_boundaries',
			'paint': {
				//'line-color': '#F66',
				'line-color': '#AAA',
				'line-width': 4,
				'line-offset':-3,
			},
			'layout': {
				'line-join':'round',
			}
		});

		//Only show havering
		//map.setFilter('havering-outline-background', ['==', ['get', 'ctyua17cd'], 'E09000016']);
		//map.setFilter('havering-outline', ['==', ['get', 'ctyua17cd'], 'E09000016']);





		//Images we need to load
		var images = [
			['marker-generic', '/marker.png', {}],
			['bank', '/bank.png', {}],
			['store', '/store.png', {}],
			['restaurant', '/restaurant.png', {}],
			['school', '/school.png', {}],
			['background', '/background.png', {stretchX:[[8,9]], stretchY:[[8,9]]}],
		];

		//Load all images, add to map, and then...
		Promise.all(
      images.map(img => new Promise((resolve, reject) => {
        map.loadImage(img[1], function (error, res) {
          map.addImage(img[0], res, img[2])
          resolve();
        })
      }))
    )
    .then(()=>{



			//Add ward label next to it 
			map.addLayer({
				'id': 'wards-labels',
				'type': 'symbol',
				'source': 'wards-points',
				'layout': {
					'text-field': [
						'format',
						['get', 'name'],
						//["feature-state", 'color'],
						{ 'font-scale': 1.0 },
					],
					"text-max-width": 5, //make narrow with lots of lines
					//"text-size": 16,
					"text-size": 13,
					"text-padding":1,
					"text-anchor":'right',
					//"icon-ignore-placement":true,
					"text-ignore-placement":true, //allow other things to be shown beneath the ward label, such as foodbank markers
					//"text-font":'Arial Unicode MS Bold',

					"icon-text-fit":"both", //experiment
					"icon-text-fit-padding":[3,26,3,8],
					"icon-ignore-placement":true,

					"icon-allow-overlap":true,
					"text-allow-overlap":true,
					"icon-image":"background",
				},
				'paint':{
					'text-color':'rgb(0,0,0)',
					'text-halo-color':"rgba(255,255,255,1)",
					'text-halo-width':2,
					'text-halo-blur':1,
					"text-translate":[-12, 0],
					"icon-translate":[-12, 0],
				},
			});


			//Add ward traffic light to indicate the tipping point group
			map.addLayer({
				'id': 'wards-circles',
				'type':'circle',
				'source':'wards-points',
				'layout':{

				},
				'paint':{
					'circle-color': ['feature-state', 'color'],
					'circle-stroke-width':2,
					'circle-stroke-color':'#FFF',
					'circle-radius':6,
					'circle-translate':[0, 0],
				}
			});

			//Wards tooltip
			map.on('mousemove', 'wards-labels', function(e){
				var feature = e.features[0];

				let html = renderToString(<WardTooltip id={feature.id} store={store} />)
				//let html = renderToStaticMarkup(<Provider store={store}><WardTooltip id={feature.id} /></Provider>)
				//let html = renderToStaticMarkup(<WardTooltip { ...props } />)

				//return

				showTooltip(e, html);
			})
			map.on('mouseleave', 'wards-labels', hideTooltip);



    	//Draw school
			map.addLayer({
				'id': 'schools',
				'type': 'symbol',
				'source': 'schools',
				'layout': {   
					"icon-image":'school',
					"icon-size":1,

					"icon-allow-overlap":true,
					"text-allow-overlap":true,

					"text-size":11,
					"text-field":['concat', 
						['round', 
							['*',
								100,
								['/', 
									['get', 'eligible_pupils'], 
									['get', 'pupils']
								]
							]
						],
						'%'
					]
				},
				'paint':{
					//'text-color':'#FFF',
					'text-halo-color':"rgba(255,255,255,1)",
					'text-halo-width':1,
					'text-halo-blur':1,
				}
			}, 'wards-labels');



			//Schools tooltip
			map.on('mousemove', 'schools', function(e){
				const props = e.features[0].properties;

				var names = props.name.trim().split('\n');
				names.sort();

				let html = renderToString((
					<Fragment>
						<div className='tooltip-title'>Free School Meals</div>
						{ names.map((name)=><div className='tooltip-item'>{ name }</div>) }
						<div className='tooltip-item'>{ props.pupils } pupils</div>
						<div className='tooltip-item'>{ props.eligible_pupils } ({Math.round(100*props.eligible_pupils/props.pupils) }%) free school meals</div>
					</Fragment>
				));

				showTooltip(e, html);
			})
			map.on('mouseleave', 'schools', hideTooltip);



    	//map.setLayoutProperty('wards-labels', 'icon-image', 'background')

			map.addLayer({
				'id': 'food_stores_restaurants',
				'type': 'symbol',
				'source': 'food_stores_restaurants',
				'layout': {   

					"icon-image":['case',
						['has', 'cluster'], ['literal', 'marker-generic'],
						['get', 'type']	
					],

					"icon-allow-overlap":true,
					"text-allow-overlap":true,

					"icon-size":1,
					"text-size":11,
					"text-field":['literal', '?'],
				},
				'paint': {
					"text-translate":[0, -3],
				}
			}, 'wards-labels');


			//Add foodpanks point layer (text value will be set later)
			map.addLayer({
				'id': 'food_banks',
				'type': 'symbol',
				'source': 'food_banks',
				'layout': {   
					"icon-image":'bank',
					"icon-size":1,
					"icon-allow-overlap":true,
				}
			}, 'wards-labels');



			map.on('mousemove', 'food_stores_restaurants', function(e){
				const feature = e.features[0];

				const test = showState.current; //should really be setting state, adding an effect, and then props.showState will be live

				//Get alphabetically ordered list of items for cluster, or single item
				let items = [];
				if(feature.properties.cluster){

					if(test.showFoodStores && feature.properties.store_names){
						items = items.concat(feature.properties.store_names.trim().split('\n').map((name)=>[name, 'store']));
					}
					if(test.showFoodRestaurants && feature.properties.restaurant_names){
						items = items.concat(feature.properties.restaurant_names.trim().split('\n').map((name)=>[name, 'restaurant']));
					}

					items.sort()
				}
				else{

					items.push([feature.properties.name, feature.properties.type])
				}

				//Render string
				const html = renderToString((
					<Fragment>
					<div className='tooltip-title'>Food Outlets</div>
						{ items.slice(0,10).map((item)=>(
							<div className="tooltip-item">
								<div className={ "outlet-icon "+item[1]+'-icon' } />
								{ item[0] }
							</div>
						)) }
						{ (items.length > 10) && <div className='tooltip-item-more'>and { items.length-10 } more</div> } 
					</Fragment>
				))

				//Show as tooltip
				showTooltip(e, html);
			});


			map.on('mouseleave', 'food_stores_restaurants', hideTooltip);
			



			map.on('mousemove', 'food_banks', function(e){
				var feature = e.features[0];

				let html = renderToString((
					<Fragment>
						<div className='tooltip-title'>Food Bank</div>
						<div className='tooltip-item'>{ feature.properties.name }</div>
					</Fragment>
				))

				showTooltip(e, html);
			})


			map.on('mouseleave', 'food_banks', hideTooltip);


			//});

			//Let other stuff happen
			setMapLoaded(true)

			update_food_outlet_visibility();

		})

		map.on('mouseleave', 'wards', function(e){
			props.setHoveredWard(null);
		});

		map.on('mousemove', 'wards', function(e){
			props.setHoveredWard(e.features[0].id);
		});



		map.on('click', function(e){
			var feature = (map.queryRenderedFeatures(e.point, { layers: ['wards'] }))[0];
			props.setShowDataPanel(!!feature);
			props.setCurrentWard(feature?feature.id:null);
		});
	}

	//When the selected ward changes, update feature state accordingly
  useChange((prevWard)=>{
  	if(!mapRef.current)return;
  	if(prevWard)mapRef.current.removeFeatureState({source:'wards', id:prevWard}, 'selected');
		if(currentWard)mapRef.current.setFeatureState({source:'wards', id:currentWard}, {selected:true});
  }, currentWard)

  //When the hovered ward changes, update feature state accordingly
  useChange((prevWard)=>{
  	if(!mapRef.current)return;
  	if(prevWard)mapRef.current.removeFeatureState({source:'wards', id:prevWard}, 'hovered');
		if(hoveredWard)mapRef.current.setFeatureState({source:'wards', id:hoveredWard}, {hovered:true});
  }, hoveredWard)




	//On changing the layer, or loading in layer data, update the layer
	useEffect(()=>{
		if(!mapRef.current)return;
		if(!mapLoaded)return;
		if(!props.lsoaData)return;
		const map = mapRef.current;

		for(let name in props.wardData){
			var ward = props.wardData[name];


			const dist = ward['ward_welfare_2020']; //todo, change to 'tipping-point'

			//const color = "rgb(255,"+Math.round((1-dist)*255)+","+Math.round((1-dist)*255)+")";
			let color = traffic_light_color(dist);

			map.setFeatureState({source:'wards', id:name}, {color});				
			map.setFeatureState({source:'wards-points', id:name}, {color});			
		}

		var layer_name = layerRef.current;
		var layer_name_original = layerRef.current;


		//If the current layer has a timeline, then current time to it (so looking at correct data)
		if(props.layers[layerRef.current].timeline){
			layer_name += '_'+props.currentTime;
		}

		//Get lsoa data for current layer
		//const lsoa_data = props.lsoaData[layer_name];
		const layer = props.layers[layerRef.current];

		let colors = layer.colors;
		let range = layer.range;

		if(layer.timeline && props.currentTime === 'diff'){
			colors = layer.diff_colors
			range = layer.diff_range
		}


		function setColour(id)
		{
			
			//const dist = lsoa_data[id]/100;
			let item = props.lsoaData[id]
			let dist = item[layer_name]

			let color = null;

			if(layer.type === 'colour'){
				if(dist === null || isNaN(dist)){
					color = null
				}
				else if(props.currentTime === 'diff'){

					let before = traffic_light_color(item[layer_name_original+'_2019']);
					let after = traffic_light_color(item[layer_name_original+'_2020']);
					if(before === after){
						color = traffic_light_colors.amber
					}
					else if(dist > 0){
						color = traffic_light_colors.red
					}
					else{
						color = traffic_light_colors.green
					}

					/*
					if(dist > 1)color = traffic_light_colors.red
					else if(dist < -1)color = traffic_light_colors.green
					else color = traffic_light_colors.amber
						*/
				}
				else{
					color = traffic_light_color(dist);
				}
			}
			else{


				//dist = dist/100;

				//get distance from min to max
				dist = (dist-range[0])/(range[1]-range[0])

				var color_index = 0;

				//Get the two colors, and distance to use
				if(colors.length === 3){
					if(dist <= 0.5){
						dist = dist*2;
					}
					else{
						dist = (dist-0.5)*2;
						color_index = 1;
					}
				}

				//const num = Math.round((1-dist)*255);
				var r = (colors[color_index][0]*(1-dist)+colors[color_index+1][0]*(dist));
				var g = (colors[color_index][1]*(1-dist)+colors[color_index+1][1]*(dist));
				var b = (colors[color_index][2]*(1-dist)+colors[color_index+1][2]*(dist));
				color = "rgb("+r+","+g+","+b+")"
			}


			//const color = "rgb("+num+","+num+",255)";
			map.setFeatureState({source:'lsoa', sourceLayer:'lsoa', id:id}, {color});											
			
			//const dist = lsoa_data[id]/100;
			//map.setFeatureState({source:'lsoa', sourceLayer:'lsoa', id:id}, {test:color, test2:color, test3:color, test2:color});											
		}

		//Update visible features (fast)
		var features = map.queryRenderedFeatures(null, {layers:['lsoa']});
		features.forEach(f => setColour(f.id));

		//After other animations have completed, animate all features 
		var prevLayer = layerRef.current;
		var prevTime = timeRef.current;
		setTimeout(()=>{
			if(prevLayer === layerRef.current && prevTime === timeRef.current){

				//TODO: if this was done in a breakable setInterval block, then it would stop it render blocking - no big deal though
				Object.keys(props.lsoaData).forEach(setColour)
			}
		}, 1000);

	}, [mapLoaded, layerRef.current, props.lsoaData, props.layers, props.currentTime]);


	function update_food_outlet_visibility()
	{
		//alert(1);

		
		//Build filter to only show outlets of an enabled type, or clusters with at least one of an enabled type
		//At the same time, build a total number of outlets using just the number of enabled types
		var filter = ['any'];
		var count = ['+'];
		if(props.showFoodStores){
			filter.push(['all', ['has', 'type'], ['==', ['get', 'type'], ['literal', 'store']]]);
			filter.push(['all', ['has', 'store_count'], ['>', ['get', 'store_count'], ['literal', 0]]]);
			count.push(['case', ['has', 'store_count'], ['get', 'store_count'], ['literal', 0]]);
		}
		if(props.showFoodRestaurants){
			filter.push(['all', ['has', 'type'], ['==', ['get', 'type'], ['literal', 'restaurant']]]);
			filter.push(['all', ['has', 'restaurant_count'], ['>', ['get', 'restaurant_count'], ['literal', 0]]]);
			count.push(['case', ['has', 'restaurant_count'], ['get', 'restaurant_count'], ['literal', 0]]);
		}

		//Apply filter
		mapRef.current.setFilter('food_stores_restaurants', filter);

		//Make the text display appropriately
		mapRef.current.setLayoutProperty('food_stores_restaurants', "text-field", ['case',
			['has', 'cluster'], ['to-string', count],
			['literal', '']
		]);

		//Show/hide the food banks (not clustering, so simple)
		mapRef.current.setLayoutProperty('food_banks', 'visibility', props.showFoodBanks?'visible':'none');

		mapRef.current.setLayoutProperty('schools', 'visibility', props.showSchools?'visible':'none');

		mapRef.current.setLayoutProperty('wards-labels', 'visibility', props.showWardLabels?'visible':'none');

		mapRef.current.setLayoutProperty('wards-circles', 'visibility', props.showWardWelfare?'visible':'none');
		
		//Alter ward label padding/position based on ward circle visibility
		mapRef.current.setLayoutProperty('wards-labels', 'icon-text-fit-padding', [3,props.showWardWelfare?26:8,3,8])
		mapRef.current.setLayoutProperty('wards-labels', 'text-anchor', props.showWardWelfare?'right':'center')
		mapRef.current.setPaintProperty('wards-labels', 'text-translate', props.showWardWelfare?[-12, 0]:[0,0])
		mapRef.current.setPaintProperty('wards-labels', 'icon-translate', props.showWardWelfare?[-12, 0]:[0,0])
	}

	//On changing a food outlet visibility option
	useEffect(()=>{
		if(!mapLoaded)return;
		var map = mapRef.current;
		if(!map)return;

		update_food_outlet_visibility();

	}, [mapLoaded, props.showFoodBanks, props.showFoodStores, props.showFoodRestaurants, props.showSchools, props.showWardLabels, props.showWardWelfare]);


	function downloadMap(e)
	{
		var map = mapRef.current;
		if(!map)return;

		//Force a render so that getCanvas won't be blank
    map._render();
		var content = map.getCanvas().toDataURL('image/png');

		//Put the image data url into the anchor, so it downloads (thanks to the download attribute)
		e.target.href = content;
	}


	return (
		<div ref={el => mapContainer.current = el} className="map-container">

			<div ref={el => tooltipDummyRef.current = el} data-tip='tooltip' data-for="layer-tip" data-html={true} ></div>

			<a 
				className="map-download-button download-button" 
				href="" 
				download="map.png" 
				data-html={true} 
				data-tip={ "<div>Download an image of the current map, showing the data layer and enabled pins</div>" } 
				data-for={"layer-tip"} 
				onClick={ downloadMap }
			/>

		</div>
	)
}

export default connect(


  (state, props)=>({
    currentWard:state.currentWard,
    hoveredWard:state.hoveredWard,
    currentLayer:state.currentLayer,
    hoveredLayer:state.hoveredLayer,
    currentTime:state.currentTime,
    showDataPanel:state.showDataPanel,
    showLayerPanel:state.showLayerPanel,
    layers:state.layers,
		lsoaData: state.lsoaData,
		wardData: state.wardData,
		wards:state.wards,
		//mapLoaded: state.mapLoaded,
		mapLocation: state.mapLocation,
		showFoodBanks: state.showFoodBanks,
		showFoodStores: state.showFoodStores,
		showFoodRestaurants: state.showFoodRestaurants,
		showSchools: state.showSchools,
		showWardLabels: state.showWardLabels,
		showWardWelfare: state.showWardWelfare,
		foodbanks: state.foodbanks,
  }),

  (dispatch, props)=>({

  	//setMapLoaded:()=>{dispatch({type:'SET_MAP_LOADED', payload:true})},
  	setLocationSilent: (location)=>{dispatch({type:'SET_LOCATION_SILENT', payload:location})},
  	setShowDataPanel: (show)=>{dispatch({type:'SHOW_DATA_PANEL', payload:show})},
  	setCurrentWard: (ward)=>{dispatch({type:'SET_CURRENT_WARD', payload:ward})},
  	setHoveredWard: (ward)=>{dispatch({type:'SET_HOVERED_WARD', payload:ward})},
  })

)(MapView);