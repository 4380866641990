import { connect } from "react-redux";

import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion"

import ReactTooltip from "react-tooltip";

import {useEffect } from "react"

import Key from './Key.js'

import Arrow from './Arrow.js'


import { traffic_light_color, traffic_light_label, fastTransition } from '../common.js'

import AnimatedNumber from './AnimatedNumber.js'

function formatNumber(num) {
  return Math.round(num).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


function nth(d){
	d = Math.round(d)

  if (d > 3 && d < 21) return d+'th';
  switch (d % 10) {
    case 1:  return d+"st";
    case 2:  return d+"nd";
    case 3:  return d+"rd";
    default: return d+"th";
  }
}

function SelectionPanel(props)
{

	//Tooltips don't work properly without this for some reason...
	useEffect(()=>ReactTooltip.rebuild())
	

	if(!props.currentWard){
		return <div className="selection-panel"><h2 style={{ textAlign:'center' }} >Select a ward</h2></div>
	}

	//Current layer and ward data
	
	//Layer names (to avoid them getting hard coded)
	const wwLayer = 'ward_welfare_2020'
	const wwLayerPre = 'ward_welfare_2019'

	const fidLayer = 'fid_2020'
	const fidLayerPre = 'fid_2019'

	const benefitLayer = 'uc_claimants_2020'
	const benefitLayerPre = 'uc_claimants_2019'

	const councilTaxLayer = 'hb_claimants_2020'
	const councilTaxLayerPre = 'hb_claimants_2019'

	const freeSchoolMealLayer = 'fsm_eligible_taking_2020'
	const freeSchoolMealLayerPre = 'fsm_eligible_taking_2019'

	//let fidScoreBreakpoints = [ 5, 10 ];
	let fidScoreBreakpoints = [ 15, 25 ];

	//Ward data for current ward
	const wardData = props.wardData[props.currentWard];
	//var population = wardData.mid_2019_population;

	//Ward name
	let name = props.wards[props.currentWard].name;

	//Calculate rankings (pre and now)
	let wwWardOrder = Object.keys(props.wards)
	wwWardOrder.sort((a,b) => props.wardData[a][wwLayer] - props.wardData[b][wwLayer])
	let wwRank = wwWardOrder.indexOf(props.currentWard);

	wwWardOrder.sort((a,b) => props.wardData[a][wwLayerPre] - props.wardData[b][wwLayerPre])
	let wwRankPre = wwWardOrder.indexOf(props.currentWard);


	let wwDisplayOrder = props.currentTime === '2019' ? wwLayerPre : wwLayer
	wwWardOrder.sort((a,b) => props.wardData[a][wwDisplayOrder] - props.wardData[b][wwDisplayOrder])

	let wwCurrentRank = props.currentTime === '2019' ? wwRankPre : wwRank


	//let wwRankMax = wwWardOrder.length

	let imdRank = wardData.imd_score_rank
	let imdRankMax = Object.keys(props.wardData).length
	var imdRankPercent = imdRank*100/imdRankMax;


	//TODO: restructure all data into something separate to be manageable
	//let data = null;

	let data = {
		'2019':{
			fid:wardData[fidLayerPre],
			ww:wardData[wwLayerPre],
			universal_credit:wardData[benefitLayerPre],
			housing_benefit:wardData[councilTaxLayerPre],
			free_school_meals:wardData[freeSchoolMealLayerPre],
			ww_rank: wwRankPre,
			population: props.wardData[props.currentWard].mid_2019_population,
			imd_rank:imdRank
		},
		'2020':{
			fid:wardData[fidLayer],
			ww:wardData[wwLayer],
			universal_credit:wardData[benefitLayer],
			housing_benefit:wardData[councilTaxLayer],
			free_school_meals:wardData[freeSchoolMealLayer],
			ww_rank: wwRank,
			population: Math.round(props.wardData[props.currentWard].mid_2019_population),
			imd_rank:imdRank
		},
		'diff':{
			fid:wardData[fidLayer]-wardData[fidLayerPre],
			ww:wardData[wwLayer]-wardData[wwLayerPre],
			universal_credit:wardData[benefitLayer]-wardData[benefitLayerPre],
			housing_benefit:wardData[councilTaxLayer]-wardData[councilTaxLayerPre],
			free_school_meals:wardData[freeSchoolMealLayer]-wardData[freeSchoolMealLayerPre],
			ww_rank: wwRank-wwRankPre,
			population: Math.round(props.wardData[props.currentWard].mid_2019_population),
			imd_rank:imdRank
		}
	}

	//Get the current and prev item
	let current = data[props.currentTime]
	let change = null
	if(props.currentTime === '2020'){
		change = data['diff']
	}

	//Show as change or not
	let showChange = props.currentTime === 'diff'


	function calcFidCategory(fid){
		if(fid > fidScoreBreakpoints[1])return 'high'
		if(fid < fidScoreBreakpoints[0])return 'low'		
		return 'medium'
	}

	function arrowType(change){
		if(change < -2)return 'up'
		if(change > 2)return 'down'
		return 'same'
	}

	function directionText(change){
		if(change > 2)return 'better than'
		if(change < -2)return 'worse than'
		return 'similar to'
	}

	function directionText2(change){
		if(change > 2)return 'an increase in'
		if(change < -2)return 'a decrease in'
		return 'no change in'
	}

	function numberChange(change){
		if(change>0)return '+'+change
		else return change
	}

	return (
		<div className="selection-panel">
			
			<AnimatePresence>
				<motion.h2 style={{position:'absolute'}} key={ name } initial={{ opacity:0, x:-10 }} animate={{ opacity:1, x:0 }} exit={{ opacity:0, x:10}} transition={fastTransition}>{ name }</motion.h2>
			</AnimatePresence>
			<div className="population">Population <div className="value">{formatNumber(current.population)}</div></div>

			
			<h3>Food Insecurity Detected</h3>

			<div className="insight-content">
				<div className="fid-stat">
					{ !showChange && <span>{Math.round(current.fid)}% {calcFidCategory(current.fid)}</span> }
					{ showChange && <span>{numberChange(Math.round(current.fid))}%</span> } 
					{ change &&	<Arrow style={{ marginLeft:'0.3em', fontSize:'0.8em'  }} direction={ arrowType(-change.fid) } />	}
				</div>
				<div className="ww-blurb">Notes: 
					{ !showChange && <span> {name } is indicating { calcFidCategory(current.fid) } food insecurity.</span> }
					{ showChange && <span> {name} is indicating { directionText2(current.fid) } food insecurity.</span> }
					{ change && <span> The score is { directionText(-change.fid) } the previous data point.</span> }
				</div>
			</div>

			<h3>Ward Welfare</h3>

			<div className="insight-content">
				<div className="ww-grid" style={{ backgroundColor:traffic_light_color(current.ww) }}>
					<div className="ww-top">
						{ traffic_light_label(current.ww) }
						{ change && <Arrow direction={ arrowType(-10000*change.ww) } className="ww-top-arrow" /> }
					</div>
					<div className="ww-stat">
						<div>
							<div className="ww-value">
								{ !showChange && <span>{ Math.round(current.universal_credit) }%</span> }
								{ showChange && <span>{ numberChange(Math.round(current.universal_credit)) }%</span> }
								{ change && <Arrow direction={ arrowType(-change.universal_credit) } className="ww-arrow" /> }
							</div>
							<div className="ww-text">universal credit</div>
						</div>
						<div>
							<div className="ww-value">
								{ !showChange && <span>{ Math.round(current.housing_benefit) }%</span> }
								{ showChange && <span>{ numberChange(Math.round(current.housing_benefit)) }%</span> }								
								{ change && <Arrow direction={ arrowType(-change.housing_benefit) } className="ww-arrow" /> }
							</div>
							<div className="ww-text">housing benefits</div>
						</div>
						<div>
							<div className="ww-value">
								{ !showChange && <span>{ Math.round(current.free_school_meals) }%</span> }
								{ showChange && <span>{ numberChange(Math.round(current.free_school_meals)) }%</span> }
								{ change && <Arrow direction={ arrowType(-change.free_school_meals) } className="ww-arrow" /> }
							</div>
							<div className="ww-text">free school meals</div>
						</div>
					</div>
				</div>

				<AnimateSharedLayout>
					<div className='ww-dots'>
						{ wwWardOrder.map((name,i)=>{

							let highlight = !showChange ? current.ww_rank : data['2020'].ww_rank

							let style
							if(i === highlight)style = {borderColor:'#333',width:'18px',height:'18px'}
							else if(name === props.hoveredWard)style = {borderColor:'#888',width:'15px',height:'15px'}
							else style = {borderColor:'#FFF',width:'15px',height:'15px'} 

							return <motion.button 
								key={ name } 
								
								//className={ wwScoreClass(props.wardData[name][wwLayer])+(i==wwRank ? ' current': '') } 
								//className={ wwScoreClass(props.wardData[name][wwLayer]) } 
								animate={ style }

								style={{
									backgroundColor:traffic_light_color(props.wardData[name][wwDisplayOrder])
								}}
								transition={ fastTransition } 
								data-html={true} 
								data-tip={ "<div>"+props.wards[name].name+" #"+(i+1)+"</div>" } 
								data-for="layer-tip"
								data-place="top"
								onClick={ ()=>props.setCurrentWard(name) }
								onMouseEnter={ ()=>props.setHoveredWard(name) }
								onMouseLeave={ ()=>props.setHoveredWard(null) }
							/>
						}
						) } 
					</div>
				</AnimateSharedLayout>

				<div className='ww-rank' >
					{ !showChange && <span>Ranked in Havering LA: {nth(current.ww_rank+1)}</span> }
					{ showChange && <span>Rank change in Havering LA: {numberChange(current.ww_rank)}</span>}

				</div>

				<div className='ww-blurb' >
					Notes: 
					{ !showChange && <span> { name } is indicating { traffic_light_label(current.ww) }.</span> } 
					{ showChange && <span> { name } is indicating { directionText2(current.ww*1000) } food insecurity.</span> }
					{ change && <span> The score is { directionText(-change.ww*1000) } the previous data point.</span> }
				</div>

			</div>

			<h3>Index of Multiple Deprivation (2019)</h3>

			<div className="insight-content">
				<div className="tipping-point-arrow-container">
					<motion.div animate={{ x:imdRankPercent+'%' }} transition={ fastTransition }>
						<div className="tipping-point-arrow" />
					</motion.div>
				</div>
				<Key colors={props.layers.imd_score_rank.colors} />

				<div className="map-legend-values">
					<div>#1</div>
					<div className="map-legend-text">most deprived</div>
					<div className="map-legend-text" style={{marginLeft: 'auto'}}>least deprived</div>
					<div>#{ imdRankMax }</div>
				</div>

				<div className="ww-rank">Ranked <AnimatedNumber value={imdRank} format={nth} /> ward in England</div>
			</div>

		</div>
	)
}

//export default SelectionPanel;

export default connect(

  (state, props)=>({
    currentWard:state.currentWard,
    //hoveredWard:state.hoveredWard,
    layers:state.layers,
    currentLayer:state.currentLayer,
    hoveredWard:state.hoveredWard,
    //hoveredLayer:state.hoveredLayer,
    wards:state.wards,
    wardData:state.wardData,
    lsoaData:state.lsoaData,
    currentTime:state.currentTime,
  }),

  (dispatch, props)=>({
  	setCurrentWard: (ward)=>{dispatch({type:'SET_CURRENT_WARD', payload:ward})},
  	setHoveredWard: (ward)=>{dispatch({type:'SET_HOVERED_WARD', payload:ward})},
  })

)(SelectionPanel);