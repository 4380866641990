
import { useMotionValue, animate } from "framer-motion"

import { useEffect, useRef} from "react"

import { fastTransition } from '../common.js'

export default function Animate({value, format})
{
	const nodeRef = useRef()
	const motionValue = useMotionValue(value)

	useEffect(()=>{
		const node = nodeRef.current;
		const controls = animate(motionValue, value, {
			...fastTransition,
			onUpdate(value){
				node.textContent = format ? format(value) : value;
			}
		})
		return () => controls.stop()

	}, [value, format, motionValue])

	return <span ref={nodeRef}></span>
}