import { connect } from "react-redux"
import { useState } from "react"

function FoodbankAdd(props)
{
	//setState();
	const [data,setData] = useState({name:'', organisation:'', address:'', location:[0,0]});

	return (
		<div className="foodbank-dialog">
			
			Foodbank add dialog
			
			<div>Name: <input onChange={function(e){ setData({...data, name:e.target.value}) }} value={data.name} ></input></div>
			<div>Organisation: <input onChange={function(e){ setData({...data, organisation:e.target.value}) }} value={data.organisation} ></input></div>
			<div>Address: <input onChange={function(e){ setData({...data, address:e.target.value}) }} value={data.data} ></input></div>
			<div>(draggable map goes here)</div>
			<div onClick={()=>{props.addFoodbank(data); props.hideFoodbankAdd()}}>save</div>
			<div onClick={ props.hideFoodbankAdd }>cancel</div>
		</div>
	)
} 

export default connect(

  (state, props)=>({
    foodbanks:state.foodbanks,
    foodbankEdit:state.foodbankEdit,
  }),

  (dispatch, props)=>({
		hideFoodbankAdd:()=>dispatch({type:'HIDE_FOODBANK_ADD'}),
		addFoodbank:(data)=>dispatch({type:'FOODBANK_ADD', payload:data}),
  })

)(FoodbankAdd);