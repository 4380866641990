import { connect } from "react-redux";


import { motion, AnimatePresence } from 'framer-motion'

import FoodbankAdd from "./FoodbankAdd.js"


import { fastTransition } from '../common.js'

const mapboxAccessToken = 'pk.eyJ1IjoiZmF0YnVkZGhhIiwiYSI6ImNpZ3o4c2JibTAwZnR2NW03YWFjd3JpdHIifQ.itQExL0tc-3pPvlsNF_ieg';
const mapboxStyle = 'mapbox/streets-v11';


function DataCollection(props)
{

	//var foodbanks = Object.entries(props.foodbanks).filter( ([id,foodbank])=>foodbank.type === props.foodbankFilter )
	var foodbanks = Object.entries(props.foodbanks)
		.map(([id,foodbank])=>
		<div key={ id } className="foodbank-panel">
			<div className="details">
				<div className="name">{foodbank.name}</div>
				<div className="organisation">{foodbank.organisation}</div>
				<div className="address">{foodbank.address}</div>
			</div>
			<img className="foodbank-map" alt="location map" src={ 
				'https://api.mapbox.com/styles/v1/'+mapboxStyle+'/static/'
				+'pin-l+F00('+foodbank.location[0]+','+foodbank.location[1]+')/'
				+foodbank.location[0]+','+foodbank.location[1]
				+',13,0.00,0.00/320x240@2x?'
				+'access_token='+mapboxAccessToken 
			} />
		</div>
	);

	return (
		<div className="content">
			<div className="foodbank" >

				<div className="foodbank-title-area">
					<h1>Food Outlets</h1>
				</div>

				<h2>Food Banks</h2>

				{ props.foodbankEdit && <FoodbankAdd /> } 
				<AnimatePresence exitBeforeEnter={false} >
					<motion.div className="foodbank-list-container" key={ props.foodbankFilter } initial={{opacity:0, x:-20}} animate={{opacity:1, x:0}} exit={{opacity:0, x:20, position:'absolute'}} transition={ fastTransition } >
						<div className="foodbank-list">
								{ foodbanks }
							<div className="foodbank-panel-spacer" />
							<div className="foodbank-panel-spacer" />
							<div className="foodbank-panel-spacer" />
							<div className="foodbank-panel-spacer" />
							<div className="foodbank-panel-spacer" />
						</div>
						<div className="foodbank-footer">Contact us at <a href="mailto:hello@olioex.com">hello@olioex.com</a> if you have more information to add to the map</div>
					</motion.div>
				</AnimatePresence>

				<h2>Food Shops, Restaurants and Takeaways by Ward</h2>


				{ Object.entries(props.wards).map(([ward_id, ward_info])=>{
					let ward = props.wardData[ward_id]

					return (
						<p key={ward_id} className="food-outlet-list-item">{ward_info.name} has <b>{ward.supermarkets + ward.food_retailers}</b> food shops, and <b>{ward.restaurants + ward.food_retailers}</b> restaurants and takeaways.</p>
					)

				}) }

				<h2>Schools in Havering</h2>
				<p>There are 91 primary and secondary schools in Havering.</p>

				<h2>Community projects in Havering</h2>
				<p className="foodbank-footer">Please contact us at <a href="mailto:hello@olioex.com">hello@olioex.com</a> if you have community project information that you would like to see published on the map.</p>

				<div style={{ marginTop:'50px' }} />

			</div>
		</div>
	)

	//<motion.div whileHover={{ scale:1.03 }} className="foodbank-panel foodbank-panel-add" onClick={(e)=>{ props.showFoodbankAdd();e.stopPropagation(); }}>+</motion.div>
}

export default connect(

  (state, props)=>({
    foodbanks:state.foodbanks,
    foodbankEdit:state.foodbankEdit,
    foodbankFilter:state.foodbankFilter,
    wardData:state.wardData,
    wards:state.wards,
  }),

  (dispatch, props)=>({
		showFoodbankAdd:()=>dispatch({type:'SHOW_FOODBANK_ADD'}),
		hideFoodbankAdd:()=>dispatch({type:'HIDE_FOODBANK_ADD'}),
		setFoodbankFilter:(type)=>dispatch({type:'SET_FOODBANK_FILTER', payload:type}),
  })

)(DataCollection);