import { createStore } from 'redux';


const initialState = {

	//lsoaData will be loaded in
	lsoaData:null,
	wardData:null,

	//These are all the wards
	wards:{
		'E05000306':{name:"Brooklands", lsoas:['E01002251', 'E01002243', 'E01002244', 'E01002245', 'E01002246', 'E01002247', 'E01002248', 'E01002249', 'E01002250']},
		'E05000307':{name:"Cranham", lsoas:['E01002252', 'E01002253', 'E01002254', 'E01002255', 'E01002256', 'E01002257', 'E01002258', 'E01002259']},
		'E05000308':{name:"Elm Park", lsoas:['E01002260', 'E01002261', 'E01002262', 'E01002263', 'E01002264', 'E01002265', 'E01002266', 'E01002267']},
		'E05000309':{name:"Emerson Park", lsoas:['E01002268', 'E01002269', 'E01002270', 'E01002271', 'E01002272', 'E01002273', 'E01002274', 'E01002275']},
		'E05000310':{name:"Gooshays", lsoas:['E01002276', 'E01002277', 'E01002278', 'E01002279', 'E01002280', 'E01002281', 'E01002282', 'E01002283', 'E01002284']},
		'E05000311':{name:"Hacton", lsoas:['E01002285', 'E01002286', 'E01002287', 'E01002288', 'E01002289', 'E01002290', 'E01002291', 'E01002292']},
		'E05000312':{name:"Harold Wood", lsoas:['E01002293', 'E01002294', 'E01002295', 'E01002296', 'E01002297', 'E01002298', 'E01002299', 'E01002300']},
		'E05000313':{name:"Havering Park", lsoas:['E01002301', 'E01002302', 'E01002303', 'E01002304', 'E01002305', 'E01002306', 'E01002307', 'E01002308']},
		'E05000314':{name:"Heaton", lsoas:['E01002309', 'E01002310', 'E01002311', 'E01002312', 'E01002313', 'E01002314', 'E01002315', 'E01002316']},
		'E05000315':{name:"Hylands", lsoas:['E01002317', 'E01002318', 'E01002319', 'E01002320', 'E01002321', 'E01002322', 'E01002323', 'E01002324']},
		'E05000316':{name:"Mawneys", lsoas:['E01002325', 'E01002326', 'E01002327', 'E01002328', 'E01002329', 'E01002330', 'E01002331', 'E01002332']},
		'E05000317':{name:"Pettits", lsoas:['E01002333', 'E01002334', 'E01002335', 'E01002336', 'E01002337', 'E01002338', 'E01002339', 'E01002340', 'E01002341']},
		'E05000318':{name:"Rainham and Wennington", lsoas:['E01002342', 'E01002343', 'E01002344', 'E01002345', 'E01002346', 'E01002347', 'E01002348','E01002349']},
		'E05000319':{name:"Romford Town", lsoas:['E01002351', 'E01002352', 'E01002353', 'E01002354', 'E01002355', 'E01002356', 'E01002358', 'E01002350', 'E01032741', 'E01032742']},
		'E05000320':{name:"St Andrew's", lsoas:['E01002359', 'E01002360', 'E01002361', 'E01002362', 'E01002363', 'E01002364', 'E01002365', 'E01002366', 'E01002367']},
		'E05000321':{name:"South Hornchurch", lsoas:['E01002368', 'E01002369', 'E01002370', 'E01002371', 'E01002372', 'E01002373', 'E01002374', 'E01002375']},
		'E05000322':{name:"Squirrel's Heath", lsoas:['E01002376', 'E01002377', 'E01002378', 'E01002379', 'E01002380', 'E01002381', 'E01002382', 'E01002383']},
		'E05000323':{name:"Upminster", lsoas:['E01002384', 'E01002385', 'E01002386', 'E01002387', 'E01002388', 'E01002389', 'E01002390', 'E01002391' ]},
	},

	//This is just to group up layers suitably for interface
	layerGroups:[
		{
			name:'Food Insecurity', 
			timeline:true,
			layers:['fid'/*,'food-stores','food-banks','bus-stops'*/], 
			blurb:'Data source: a blend of public and proprietary data'
		},
		{
			name:'Ward Welfare', 
			timeline:true,
			layers:['ward_welfare','uc_claimants', 'hb_claimants', 'fsm_eligible_taking'], 
			blurb:'Data source: Factorial analysis of established factors that weaken communities blended with proprietary data to rank the propensity for areas to sink into food poverty'
		},
		{
			name:'Deprivation', 
			timeline:false,
			layers:['imd_score_rank','income_score_rank','employment_score_rank','edu_skills_training_score_rank','health_disability_score_rank','crime_score_rank','barriers_housing_services_score_rank','living_environment_score_rank'], 
			blurb:'Data source: Public data (some hard to acquire but all publicly available)'
		},
	],

	//TODO: store LSOA data, and at runtime convert to ward via mapping

	//will add _2020 or _2019 to timeline layers

	//Data and display settings for each layer
	layers:{

		//red
		'fid':{name:'Food Insecurity Detected', 
			tooltip:'Food insecurity refers to people living with insecure access to food on account of financial vulnerability or food access disruptions (Loopstra, 2020).', timeline:true, format:'?%', 
			//colors:[[255,255,255], [0,0,255]], range:[13,37], labels:['lowest insecurity', 'highest insecurity'],
			colors:[[255,255,255], [0,0,255]], range:[13,37], labels:['lowest insecurity', 'highest insecurity'],
			diff_colors:[[0,180,0],[255,255,255],[255,0,0]], diff_range:[-10,10], diff_labels:['decreasing', 'increasing'],
			//tooltip:'Food insecurity refers to people living with insecure access to food on account of financial vulnerability or food access disruptions (Loopstra, 2020).', timeline:true, format:'?%', 
			//colors:[[255,255,255], [0,0,255]], range:[0,15], labels:['lowest insecurity', 'highest insecurity'],
			//diff_colors:[[0,180,0],[255,255,255],[255,0,0]], diff_range:[-8,8], diff_labels:['decreasing', 'increasing'],
		},

		//yellow
		'ward_welfare':{name:'Ward Welfare', 
			tooltip:'Ward welfare refers to the wellbeing of an area, in terms of economic and housing needs, as well as estimated food insecurity.', timeline:true, type:'colour', format:'', 
			colors:[[68,170,68],[255,176,0],[255,68,68]], range:[0,15], labels:['lowest need', 'highest need'],
			diff_colors:[[68,170,68],[255,176,0],[255,68,68]], diff_range:[-8,8], diff_labels:['decreasing need', 'increasing need'],
		},

		'uc_claimants':{name:'Universal Credit', 
			tooltip:'The proportion of residents receiving Universal Credit, due to low income, being out of work or not being able to work (Universal credit - GOV.UK, 2020).', timeline:true, format:'?%', 
			colors:[[255,255,255], [0,0,255]], range:[0, 15], labels:['lowest need', 'highest need'],
			diff_colors:[[0,180,0],[255,255,255],[255,0,0]], diff_range:[-8,8], diff_labels:['decreasing need', 'increasing need'],
		},

		'hb_claimants':{name:'Housing Benefits', 
			tooltip:'The proportion of residents receiving housing benefits, compiled from monthly returns of housing and council tax benefit claims from each individual local authority (Housing Benefit - GOV. UK, 2020).', timeline:true, format:'?%', 
			colors:[[255,255,255], [0,0,255]], range:[0, 10], labels:['lowest need', 'highest need'],
			diff_colors:[[0,180,0],[255,255,255],[255,0,0]], diff_range:[-1,1], diff_labels:['decreasing need', 'increasing need'],
		},

		'fsm_eligible_taking':{name:'Free School Meals', 
			tooltip:'The proportion of pupils eligible for and taking free school meals in the 2019-2020 academic years, in primary and secondary schools across England (Explore Education Statistics, 2020).', timeline:true, format:'?%',
			colors:[[255,255,255], [0,0,255]], range:[0,25], labels:['lowest need', 'highest need'],
			diff_colors:[[0,180,0],[255,255,255],[255,0,0]], diff_range:[-10,10], diff_labels:['decreasing need', 'increasing need'],
		},

		//blue
		'imd_score_rank':{name:'Index of Multiple Deprivation', colors:[[0,0,255],[255,255,255]], tooltip:'Statistics on relative deprivation in small areas in England (Indices of Multiple Deprivation - GOV.UK, 2019).', date:'2019', range:[1,32845], format:'#?', labels:['most deprived', 'least deprived']},
		'income_score_rank':{name:'Income Deprivation', colors:[[0,0,255],[255,255,255]], tooltip:'The Income Deprivation Domain measures the proportion of the population experiencing deprivation relating to low income (Indices of Multiple Deprivation - GOV.UK, 2019).', date:'2019', range:[1,32845], format:'#?', labels:['most deprived', 'least deprived']},
		'employment_score_rank':{name:'Employment Deprivation', colors:[[0,0,255],[255,255,255]], tooltip:'The Employment Deprivation Domain measures the proportion of the working-age population in an area involuntarily excluded from the labour market (Indices of Multiple Deprivation - GOV.UK, 2019).', date:'2019', range:[1,32845], format:'#?', labels:['most deprived', 'least deprived']},
		'edu_skills_training_score_rank':{name:'Education Deprivation', colors:[[0,0,255],[255,255,255]], tooltip:'The Education, Skills and Training Deprivation Domain measures the lack of attainment and skills in the local population (Indices of Multiple Deprivation - GOV.UK, 2019).', date:'2019', range:[1,32845], format:'#?', labels:['most deprived', 'least deprived']},   
		'health_disability_score_rank':{name:'Health Deprivation and Disability', colors:[[0,0,255],[255,255,255]], date:'2019', tooltip:'The Health Deprivation and Disability Domain measures the risk of premature death and the impairment of quality of life through poor physical or mental health (Indices of Multiple Deprivation - GOV.UK, 2019).', range:[1,32845], format:'#?', labels:['most deprived', 'least deprived']},
		'crime_score_rank':{name:'Crime', colors:[[0,0,255],[255,255,255]], date:'2019', tooltip:'The Crime Domain measures the risk of personal and material victimisation at local level (Indices of Multiple Deprivation - GOV.UK, 2019).', range:[1,32845], format:'#?', labels:['most deprived', 'least deprived']},
		'barriers_housing_services_score_rank':{name:'Barriers to Housing and Services', colors:[[0,0,255],[255,255,255]], date:'2019', tooltip:'The Barriers to Housing and Services Domain measures the physical and financial accessibility of housing and local services (Indices of Multiple Deprivation - GOV.UK, 2019).', range:[1,32845], format:'#?', labels:['most deprived', 'least deprived']},
		'living_environment_score_rank':{name:'Living Environment Deprivation', colors:[[0,0,255],[255,255,255]], date:'2019', tooltip:'The Living Environment Deprivation Domain measures the quality of the local environment (Indices of Multiple Deprivation - GOV.UK, 2019).', range:[1,32845], format:'#?', labels:['most deprived', 'least deprived']},
	},

	//TODO: add min, max, min-label, max-label

	//All the foodbanks (to break down into categories, or have a 'type' field?)
	foodbanks:{
		1:{
			type:'bank',
			name:"Collier Row and Romford Foodbank",
			organisation:"Trussell Trust",
			address:"The Wykehame Centre & Church House,\nMarkey Pl\nRomford\nRM1 3AB",
			location:[0.1823047,51.5800606],
		},
		2:{
			type:'bank',
			name:"Harold Hill Foodbank",
			organisation:"Trussell Trust",
			address:"Unit 1,\nGuardian Business Centre,\nFaringdon Ave,\nHarold Hill,\nRomford\nRM3 8FD",
			location:[0.2196509,51.598245],
		},

		3:{
			type:'bank',
			name:"The Ascension Church",
			organisation:"",
			address:"",
			location:[0.1583328,51.5983906],
		},
		4:{
			type:'bank',
			name:"Romford Baptist Church",
			organisation:"",
			address:"",
			location:[0.1858538,51.5809298],
		},
		5:{
			type:'bank',
			name:"Hornchurch Distribution Centre",
			organisation:"",
			address:"",
			location:[0.1961591,51.5656473],
		},
		6:{
			type:'bank',
			name:"St. Edward's CofE Church",
			organisation:"",
			address:"",
			location:[0.1823047,51.5800606],
		}

		/*
		3:{
			type:'store',
			name:"House of Faith Connections",
			organisation:"Trussell Trust",
			address:"House of Faith Connections,\n104 New Rd,\nDagenham\nRM9 6PE",
			location:[0.23, 51.57],
		}
		*/
	},

	user:false,

	//View state
	foodbankEdit:false,
	//currentLayer:'imd_score_rank',
	currentLayer:'fid',
	currentPage:'map',
	//currentPage:'graph',
	hoveredWard:'',
	currentFoodBank:2,
	currentFoodBankFilter:'',

	foodbankFilter:'bank', //bank/store/restaurant

	showFoodBanks:false,
	showFoodStores:false,
	showFoodRestaurants:false,
	showSchools:false,
	showWardLabels:true,
	showWardWelfare:true,

	showLayerPanel:true,
	showDataPanel:false,

	currentLAD:'E09000016',
	currentWard:'',

	currentTime:'2020', //2019 / 2020 / diff
	graphLayerX:'crime',
	graphLayerY:'health',
	dataGrid:{
		sortColumn:'name',
		sortAscending:true,
		disabledColumns:[],
		showMenu:false,
	},
	mapLocation:{
		//lat:51.5537,
		//lng:0.2140,
		//zoom:11.25,
		lat:51.5607,
		lng:0.2108,
		zoom:10.80,

		updateCounter:0,
	},
	mapLoaded:false,
	tourShowing:false,
	showClippy:false,
}


//Single global reducer (todo: split into multiple if needed)
function reducer(state = initialState, action){

	switch(action.type){

		case 'SET_SHOW_WARD_LABELS':{
			return {...state, showWardLabels:action.payload}
		}

		case 'SET_SHOW_WARD_WELFARE':{
			return {...state, showWardWelfare:action.payload}
		}

		case 'SET_CURRENT_USER':{
			return {...state, 
				user:action.payload,
				showClippy:action.payload && action.payload.username === 'clippy'
			}
		}

		case 'SET_FOODBANK_FILTER':{
			return {...state, foodbankFilter:action.payload}
		}

		case 'SET_SHOW_CLIPPY':{
			return {...state, showClippy:action.payload}
		}
		
		case 'SET_TOUR_SHOWING':{
			return {...state, tourShowing:action.payload}
		}

		case 'SET_CURRENT_TIME':{
			return {...state, currentTime:action.payload}
		}

		case 'SET_MAP_LOADED':{
			return {...state, mapLoaded:true}
		}

		case 'SET_INITIAL_DATA':{
			return {...state, ...action.payload}
		}

		case 'SET_DATAGRID_SORT':{
			const {column,ascending} = action.payload;
			return {...state, dataGrid:{...state.dataGrid, sortColumn:column, sortAscending:ascending} }
		}

		case 'SHOW_DATAGRID_COLUMN':{
			const {column, show} = action.payload;
			if(state.dataGrid.disabledColumns.includes(column) === show)return state;
			var disabledColumns = state.dataGrid.disabledColumns.slice(0);
			if(show){
				disabledColumns.push(column);				
			}
			else{
				disabledColumns = disabledColumns.filter(x=>x!==column)
			}
			return {...state, dataGrid:{...state.dataGrid, disabledColumns}}
		}
		
		case 'SHOW_DATAGRID_MENU':{
			const showMenu = action.payload
			return {...state, dataGrid:{...state.dataGrid, showMenu}}	
		}

		case 'SET_PAGE': {
			const { page } = action.payload;
			return {...state,	currentPage : page}
		}

		case 'SET_LOCATION': {
			return {...state,	mapLocation:{...action.payload, updateCounter:state.mapLocation.updateCounter+1}}
		}

		case 'SET_LOCATION_SILENT': { 
			return {...state,	mapLocation:{...action.payload, updateCounter:state.mapLocation.updateCounter}}
		}

		case 'SET_PANEL':{
			const {panel, value} = action.payload;
			let state2 = {...state}
			state2[panel] = value;
			return state2;			
		}

		case 'SHOW_PANEL':{
			const toggle = action.payload;
			let state2 = {...state}
			state2[toggle] = !state2[toggle];
			return state2;
		}
	
		case 'SHOW_DATA_PANEL':{
			return {...state,	showDataPanel:action.payload}			
		}

		case 'SET_CURRENT_WARD':{
			return {...state,	currentWard:action.payload}
		}

		case 'SET_HOVERED_WARD':{
			return {...state,	hoveredWard:action.payload}
		}

		case 'SET_CURRENT_LAYER':{
			return {...state,	currentLayer:action.payload}
		}

		case 'SET_HOVERED_LAYER':{
			return {...state,	hoveredLayer:action.payload}
		}

		case 'SHOW_FOODBANK_ADD':{
			return {...state,	foodbankEdit:true}
		}

		case 'HIDE_FOODBANK_ADD':{
			return {...state,	foodbankEdit:false}
		}

		case 'FOODBANK_ADD':{
			var foodbanks = {...(state.foodbanks)};
			foodbanks['test'] = action.payload;
			return {...state, foodbanks}
		}

		case 'SET_SHOW_FOOD_STORES':{
			return {...state, showFoodStores:action.payload};
		}

		case 'SET_SHOW_FOOD_BANKS':{
			return {...state, showFoodBanks:action.payload};
		}

		case 'SET_SHOW_FOOD_RESTAURANTS':{
			return {...state, showFoodRestaurants:action.payload};	
		}

		case 'SET_SHOW_SCHOOLS':{
			return {...state, showSchools:action.payload};	
		}

		default:
			return state;
	}
}

//Redux Store
const store = createStore(reducer, 
	window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);


export default store;