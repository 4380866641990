import { motion } from "framer-motion"

import { fastTransition } from '../common.js'


export default function Key({colors, steps=10})
{
	//Create array of colors
	var list = [];
	for(let j=0; j<colors.length-1; ++j){
		for(let i=0; i<steps; ++i){
			list.push([
				Math.round(colors[j][0]*(steps-i)/steps  + colors[j+1][0]*(i)/steps),
				Math.round(colors[j][1]*(steps-i)/steps  + colors[j+1][1]*(i)/steps),
				Math.round(colors[j][2]*(steps-i)/steps  + colors[j+1][2]*(i)/steps)
			])
		}
	}
	list.push(colors[colors.length-1])

	return (
		<div className="map-legend-bar">
			{ list.map((color, i) => <motion.div key={i} animate={{ backgroundColor: 'rgb('+color[0]+','+color[1]+','+color[2]+')' }} transition={ fastTransition } /> )}
		</div>
	)
}