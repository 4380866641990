import Clippy from './Clippy.js'
import Tour from './Tour.js'
import {connect} from 'react-redux'

function TestPage(props)
{
  
	var definitions = [
		{	
			name:"Index of Multiple Deprivation",
			source:["https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019"],
			published:'26 September 2019',
			temporal:'2015-2019',
			spatial:'LSOA, ward, Local Authority',
			description:<div>
				<p>The English Indices of Deprivation 2019 is a publicly available measure of deprivation that goes beyond material metrics (<a href="https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019">Indices of Multiple Deprivation - GOV.UK, 2019</a>). It includes seven relative domains of destitution across income, employment, health, education, living environment barriers, housing and crime rate. These are measured across small areas in England  (32,844 Lower Layer Super Output Areas, or LSOAs).</p>
				<p>The seven domains of deprivation are combined to form an overall relative measure known as the Index of Multiple Deprivation (IMD).</p>
				<p>The ranks and deciles published for the Indices of Deprivation are based on scores: the larger the score, the more deprived the area (and the lower its rank).</p>
			</div>,
		},

		{
			name:"Income Deprivation",
			source:["https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019"],
			published:"26 September 2019",
			temporal:"2015-2019",
			spatial:"LSOA, ward, Local Authority",
			description:<div>
				The <b>Income Deprivation Domain</b> measures the proportion of the population experiencing deprivation relating to low income. The definition of low income includes both those people that are out-of-work, and those that are in work but who have low earnings. The larger the score, the more deprived the area (<a href="https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019">Indices of Multiple Deprivation - GOV.UK, 2019</a>).
			</div>,
		},

		{
			name:"Employment Deprivation",
			source:["https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019"],
			published:"26 September 2019",
			temporal:"2015-2019",
			spatial:"LSOA, ward, Local Authority",
			description:<div>
				The <b>Employment Deprivation Domain</b> measures the proportion of the working-age population in an area involuntarily excluded from the labour market. This includes people who would like to work but are unable to do so due to unemployment, sickness or disability, or caring responsibilities. The larger the score, the more deprived the area (<a href="https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019">Indices of Multiple Deprivation - GOV.UK, 2019</a>).
			</div>,
		},

		{
			name:"Education, Skills and Training Deprivation",
			source:["https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019"],
			published:"26 September 2019",
			temporal:"2015-2019",
			spatial:"LSOA, ward, Local Authority",
			description:<div>
				The <b>Education, Skills and Training Deprivation Domain</b> measures the lack of attainment and skills in the local population. The indicators fall into two sub-domains: one relating to children and young people, and one relating to adult skills. The larger the score, the more deprived the area (<a href="https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019">Indices of Multiple Deprivation - GOV.UK, 2019</a>).
			</div>,
		},

		{
			name:"Health Deprivation and Disability",
			source:["https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019"],
			published:"26 September 2019",
			temporal:"2015-2019",
			spatial:"LSOA, ward, Local Authority",
			description:<div>
				The <b>Health Deprivation and Disability Domain</b> measures the risk of premature death and the impairment of quality of life through poor physical or mental health. The domain measures morbidity, disability and premature mortality but not aspects of behaviour or environment that may be predictive of future health deprivation. The larger the score, the more deprived the area (<a href="https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019">Indices of Multiple Deprivation - GOV.UK, 2019</a>).
			</div>,
		},

		{
			name:"Crime Domain",
			source:["https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019"],
			published:"26 September 2019",
			temporal:"2015-2019",
			spatial:"LSOA, ward, Local Authority",
			description:<div>
				The <b>Crime Domain</b> measures the risk of personal and material victimisation at local level.The larger the score, the more deprived the area (<a href="https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019">Indices of Multiple Deprivation - GOV.UK, 2019</a>).
			</div>,
		},

		{
			name:"Barriers to Housing and Services",
			source:["https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019"],
			published:"26 September 2019",
			temporal:"2015-2019",
			spatial:"LSOA, ward, Local Authority",
			description:<div>
				The <b>Barriers to Housing and Services Domain</b> measures the physical and financial accessibility of housing and local services. The larger the score, the more deprived the area (<a href="https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019">Indices of Multiple Deprivation - GOV.UK, 2019</a>).
			</div>,
		},

		{
			name:"Living Environment Deprivation",
			source:["https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019"],
			published:"26 September 2019",
			temporal:"2015-2019",
			spatial:"LSOA, ward, Local Authority",
			description:<div>
				The <b>Living Environment Deprivation Domain</b> measures the quality of the local environment, in terms of the quality of housing, as well as air quality and road traffic accidents. The larger the score, the more deprived the area (<a href="https://www.gov.uk/government/statistics/english-indices-of-deprivation-2019">Indices of Multiple Deprivation - GOV.UK, 2019</a>).
			</div>,
		},

		{
			name:"Food Banks",
			source:["https://www.foodaidnetwork.org.uk/","https://www.trusselltrust.org/about/"],
			published:"30 October 2020",
			temporal:"2020",
			spatial:"geographic coordinates",
			description:<div>
				This layer includes information on food banks and centres across England, as documented by the biggest food aid networks, the Trussell Trust and the Independent Food Aid Network. <b>Food banks and centres</b> provide emergency food and support to help people resolve the crises they face (The Trussell Trust, 2020).
			</div>,
		},

		{
			name:"Food Stores",
			source:["https://ratings.food.gov.uk/open-data/en-GB"],
			published:"30 October 2020",
			temporal:"2020",
			spatial:"geographic coordinates",
			description:<div>
				This layer provides information on the location of <b>supermarkets</b> across England, as documented by the Food Standards Agency in their process of providing food hygiene ratings to businesses (FSA, 2020). 				
			</div>,
		},

		{
			name:"Restaurants and Takeaways",
			source:["https://ratings.food.gov.uk/open-data/en-GB"],
			published:"30 October 2020",
			temporal:"2020",
			spatial:"geographic coordinates",
			description:<div>
				This layer provides information on the location of <b>restaurants, cafes and canteens</b>, and <b>takeaways and sandwich shops</b> across England, as documented by the Food Standards Agency in their process of providing food hygiene ratings to businesses (FSA, 2020).
			</div>,
		},


		{
			name:"Housing Benefits",
			source:["https://stat-xplore.dwp.gov.uk/"],
			published:"03 November 2020",
			temporal:"Pre-COVID: per capita average for September-November 2019, Post-COVID: per capita average for April-June 2020",
			spatial:"LSOA, ward, Local Authority",
			description:<div>
				The percentage of residents receiving housing benefits, compiled from monthly returns of housing and council tax benefit claims from each individual local authority. Entitlement to housing benefits is determined by employment status, income, or the receipt of income-related benefits, and is in the process of being replaced by Universal Credit (<a href="https://www.gov.uk/housing-benefit">Housing Benefit - GOV. UK, 2020</a>).
			</div>,
		},

		{
			name:"Universal Credit",
			source:["https://stat-xplore.dwp.gov.uk/"],
			published:"03 November 2020",
			temporal:"Pre-COVID: per capita average for September-November 2019, Post-COVID: per capita average for April-June 2020",
			spatial:"LSOA, ward, Local Authority",
			description:<div>
				The percentage of residents receiving Universal Credit, due to low income, being out of work or not being able to work (Universal Credit - <a href="https://www.gov.uk/universal-credit">GOV.UK, 2020</a>).
			</div>,
		},

		{
			name:"Free-School Meals",
			source:["https://explore-education-statistics.service.gov.uk/find-statistics/school-pupils-and-their-characteristics"],
			published:"Data for the 2019-2020 academic year was published on 27 October 2020. Data for the 2018-2019 academic year was published on 27 June 2019.",
			temporal:"2018-2019 and 2019-2020 academic years",
			spatial:"LSOA, ward, LA",
			description:<div>
				The percentage of pupils eligible for and taking <b>free school meals</b> in the 2019-2020 academic year, in primary and secondary schools across Havering (Explore Education Statistics, 2020). Entitlement to free school meals is determined by the receipt of income-related benefits. 
			</div>,
		},
		{
			name:"Food Insecurity Detected",
			description:<div>
				<p>The percentage of residents in food insecurity.</p>
				<p>Food insecurity refers to the situation where people are living with unreliable access to food due to their financial vulnerability (<a href="https://foodfoundation.org.uk/wp-content/uploads/2020/04/Report_COVID19FoodInsecurity-final.pdf">Loopstra, 2020</a>). Those in food insecurity report anxiety about not having enough to eat, running out of food, and not eating balanced meals simply because of a lack of money (<a href="https://www.ers.usda.gov/topics/food-nutrition-assistance/food-security-in-the-us/survey-tools/">US DA, 2020</a>). Since the outbreak of the COVID-19 pandemic, such circumstances are common, but additionally now include risk of disruption to food access (<a href="https://data.london.gov.uk/dataset/summaries-of-external-research-on-covid-19">Greater London Authority, 2020</a>).</p>
				<p>Food insecurity has been estimated in this project via analysis of food-sharing data from OLIO and the Greater London Authority (GLA). OLIO, the world’s most popular food-sharing platform, provides unprecedented insight into destitution through its usage data. As of August 2020, OLIO’s user base across London consisted of 45,068 individuals, of which 1,497 responded to an online survey measuring food insecurity (based upon the USDA’s module for measurement of insecurity). This survey was augmented with the GLA’s 2019 Survey of Londoners, another important resource for understanding the population-wide prevalence of food deprivation. The GLA survey included 6,601 respondents and measured food insecurity using a shortened version of the US DA’s module.</p>
				<p>While important, such surveys are actually quite sparse in comparison to overall population sizes across London. However, the extensive coverage of OLIO data across London (covering over 96% of London LSOAs) allows us to create mathematical models of food insecurity from them, even at small area levels. Our models recognize food-seeking behaviour occurring on the platform, aggregating food insecurity recognized to LSOA levels and calibrating against the survey results. For areas where there is lower platform usage, a further regression model is able to match against areas with identical characteristics but higher data support, to produce an indicative food insecurity score for each LSOA.</p>
				<p>An area is considered at risk of food insecurity if the percentage of destitute residents is above the local (London) average. This captures the fact that food insecurity is distinct from general deprivation, and that some areas have been disproportionately affected by loss of employment and food access disruptions brought about by the COVID-19 pandemic.</p>
			</div>,
		},
		{
			name:"Ward Welfare",
			temporal:"Pre and after the COVID-19 pandemic",
			spatial:"4,835 LSOAs in 33 Local Authorities in London",
			description:<div>
				<p>Ward welfare refers to the wellbeing of an area, in terms of economic and housing needs, as well as estimated food insecurity. An area that is showing concern is an area that is ranked moderate or high relative to other areas in Havering in terms of:</p>
				<ul>
					<li>general deprivation (IMD score)</li>
					<li>economic need (percentage of Universal Credit claimants before and after the COID-19 pandemic)</li>
					<li>housing need (percentage of Housing Benefit claimants before and after the COID-19 pandemic)</li>
					<li>economic insecurity as experienced by school pupils taking free-school meals in 2019 and 2020</li>
					<li>food insecurity prevalence estimates before and after the COVID-19 pandemic</li>
				</ul>
			</div>,
		},
	]

	return (
		<div className="content">
			<div className="definitions-page">
				<h1>Definitions</h1>
				{ definitions.map(item=>(
					<div key={item.name}>
						<h2>{item.name}</h2>
						<div className="meta">
							<div>{ item.description }</div>
							{ item.source && <div><h3>Source</h3>{ item.source.map((url,id) => <a key={id} href={url} target="_blank" rel="noreferrer">{url}</a> )}</div> }
							{ item.published && <div><h3>Last published</h3>{item.published}</div> }
							{ item.temporal && <div><h3>Temporal</h3>{item.temporal}</div> }
							{ item.spatial && <div><h3>Spatial</h3>{item.spatial}</div> }
						</div>
					</div>
				)) } 
			</div>

			<Tour /> 
			<Clippy />

		</div>
	)
	

	/*

				<p>
					<button className="button" onClick={props.showTour}>show tour</button>
				</p>
				<p>
					<button className="button" onClick={()=>props.doShowClippy(!props.showClippy)}>{props.showClippy?"hide clippy":"show clippy"}</button>
				</p>

				<Tour /> 
				<Clippy />

	*/

}

export default connect(

  (state, props)=>({
  	showClippy:state.showClippy,
  }),
  (dispatch, props)=>({
  	showTour:()=>dispatch({type:'SET_TOUR_SHOWING', payload:true}),
  	doShowClippy:(show)=>dispatch({type:'SET_SHOW_CLIPPY', payload:show}),
  })

)(TestPage);

//SET_TOUR_SHOWING