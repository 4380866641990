import { connect } from "react-redux";
import { motion, AnimatePresence } from 'framer-motion'

import CovidRocker from './CovidRocker.js'

import { fastTransition } from '../common.js'


function MapTitle(props)
{
	const layer = props.layers[props.currentLayer];

	return (
		<div className="map-title-block">

			<AnimatePresence>

			<motion.div key={ props.currentLayer } className="title" initial={{ opacity:0, x:-10 }} animate={{ opacity:1, x:0 }} exit={{ opacity:0, x:10}} transition={fastTransition}>
				{ layer.name }
			</motion.div>

			{ layer.timeline && (
				<motion.div key="timeline-button" className="map-timeline-switch" initial={{ opacity:0, x:-10 }} animate={{ opacity:1, x:0 }} exit={{ opacity:0, x:10}} transition={fastTransition} >
					<CovidRocker />
				</motion.div>
			)}

			{ !layer.timeline && (
				<motion.div key={layer.date+'-text'} className="map-timeline-date" initial={{ opacity:0, x:-10 }} animate={{ opacity:1, x:0 }} exit={{ opacity:0, x:10}} transition={fastTransition}>{ layer.date }</motion.div>
			)}

			</AnimatePresence>


		</div>
	)
}


export default connect(

  (state, props)=>({
    currentLayer:state.currentLayer,
    layers:state.layers,
  }),

  (dispatch, props)=>({

  })

)(MapTitle);