import { connect } from "react-redux"

import Key from './Key.js'


function MapLegend(props)
{
	const layer = props.layers[props.currentLayer]

	//Get properties to display
	var labels = layer.labels
	var colors = layer.colors
	var range = layer.range
	var steps = 9

	//If timeline mode, and showing diff, then use alternative properties
	if(layer.timeline && props.currentTime === 'diff'){
		labels = layer.diff_labels
		colors = layer.diff_colors
		range = layer.diff_range
	}

	if(layer.type === 'colour'){
		steps = 1
	}

	//Format range numbers
	const range_from = layer.format.replace('?', range[0])
	const range_to = layer.format.replace('?', range[1])

	return (
		<div className="map-legend">
			<Key colors={ colors } steps={ steps } />
			<div className="map-legend-values">
				<div>{ range_from }</div>
				<div className="map-legend-text">{ labels[0] }</div>
				<div className="map-legend-text" style={{ marginLeft:'auto' }}>{ labels[1] }</div>
				<div>{ range_to }</div>
			</div>
		</div>
	)
}



export default connect(


  (state, props)=>({
    currentWard:state.currentWard,
    hoveredWard:state.hoveredWard,
    currentLayer:state.currentLayer,
    layers:state.layers,
		lsoaData: state.lsoaData,
		wardData: state.wardData,
		currentTime: state.currentTime,
  }),

  (dispatch, props)=>({

  })

)(MapLegend);