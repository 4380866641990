import { connect } from "react-redux"


import { traffic_light_color } from '../common.js'

function formatNumber(num) {
  var number = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  return <span>{ number }</span>
}

function formatLight(num){
  return <div className={ "cell-red" } style={{backgroundColor:traffic_light_color(num)}} />
}

function WardTooltip(props)
{
	//Get the layer
	const layer = props.layers[props.currentLayer]
	let dataName = props.currentLayer
	const wardData = props.wardData[props.id]
	const ward = props.wards[props.id]
	let layerName = layer.name

	//Get value for current layer, taking into account timeline layers
	let value = wardData[dataName]
	if(layer.timeline){
		if(props.currentTime === 'diff'){
			value = wardData[dataName+'_2020'] - wardData[dataName+'_2019']
		}
		else{
			value = wardData[dataName+'_'+props.currentTime]
		}

		if(props.currentTime === 'diff'){
			layerName += ' (change)'
		}
		else if(props.currentTime === '2019'){
			layerName += ' (pre-covid)'
		}
		else if(props.currentTime === '2020'){
			layerName += ' (now)'
		}
	}

	//Format value
	if(layer.format === '?%')
		value = layer.format.replace('?', Math.round(value))
	else if(layer.type === 'colour' && props.currentTime !== 'diff')
		value = formatLight(value)
	else
		value = formatNumber(Math.round(value))

	//console.log({layer,dataName,wardData,ward,currentTime:props.currentTime})

	return (
		<div>
			<div><b>{ ward.name }</b></div>
			<div style={{ display:'flex', flexDirection:'row' }} >{ layerName }:<div style={{ width:'5px' }} /> { value } </div>
		</div>
	)
}


export default connect(


  (state, props)=>({
    currentLayer:state.currentLayer,
    layers:state.layers,
		wardData: state.wardData,
    currentTime:state.currentTime,
    wards:state.wards,
  }),

  (dispatch, props)=>({

  })

)(WardTooltip);