
import { connect } from "react-redux";
//import { useEffect, useRef } from 'react';

import { motion } from "framer-motion"

import { fastTransition } from '../common.js'


function SidePanel({children, toggle, doToggle, ...props})
{
	var className = props.className+' '+(toggle?'visible':'hidden');

	//Get arrow direction
	var reverse = toggle;
	if(props.buttonIcon==='rightarrow'){
		reverse = !reverse;
	}

	return (
		<motion.div {...props} initial={{x:props.buttonIcon==='rightarrow'?100:-100, opacity:0}} animate={{x:0, opacity:1}} className={className}  layout={true} transition={{ fastTransition }} >
			{children}
			<button className="appear-button" onClick={ doToggle }>
				{ props.buttonName }
				<motion.div 
					className="arrow-side arrow-right" 
					initial={false} 
					animate={{ transform:reverse?'rotate(180deg)':'rotate(0deg)' }} 
				/>
			</button>
		</motion.div>
	)
}

//export default SidePanel;


export default connect(

  (state, props)=>({

    toggle:state[props.toggle],
  }),

  (dispatch, props)=>({

  	doToggle: ()=>{
  		dispatch({type:'SHOW_PANEL', payload:props.toggle})
  	}
  })

)(SidePanel);