import Tour from 'reactour2'
import {connect}  from 'react-redux'


function tour(props)
{

	const steps = [];

	//TODO: set to a standard state, so that nothing unexpected happens

	steps.push({
    selector: '.menu-bar button:nth-child(1)',
    content: `First switch to the map`,
  	action: ()=>{ props.setPage('map'); props.setPanel('showLayerPanel', true) },
  });

	steps.push(

		{
	    selector: '.data-layer',
	    content: `This is the data layer window`,
	  	action: ()=>{ props.setPage('map'); props.setPanel('showLayerPanel', true) },
	  },

		{
	    //selector: '.layer-button-current',
	    selector: '.layer-panel.visible .layer-button-current',
	    content: `This is the currently selected layer`,
	  	action: ()=>{ props.setPage('map'); props.setPanel('showLayerPanel', true) },
	  },

		{
	    selector: '.mapboxgl-map',
	    content: `This is the map`,
	  	action: ()=>{ props.setPage('map'); props.setLocation({lat:0.138182,lng:51.623491,zoom:12}) },
	  },
	  {
	  	selector: '.mapboxgl-map',
	  	content: <div>Click on a ward to look at it.<br/>I've clicked on 'Havering Park' for you</div>,
	  	action: ()=>{ props.setPage('map'); props.setCurrentWard('E05000313') },
	  },
	  {
	  	selector: '.mapboxgl-map',
	  	content: <div>Click on the 'data' tab to open the data panel</div>,
	  	action: ()=>{ props.setPage('map'); props.setPanel('showDataPanel', true) },
	  },
	  {
	  	selector: '.tipping-point-graph',
	  	content: 'This is the tipping point graph',
	  	action: ()=>{ props.setPage('map'); props.setPanel('showDataPanel', true) },
	  },
	  {
	  	selector: '.menu-bar',
	  	content: 'These are the main areas',
	  	action: ()=>{ props.setPage('map') },
	  },
	  {
	  	selector: '.menu-bar button:nth-child(2)',
	  	content: "I've clicked on 'Data View'",
	  	action: ()=>{ props.setPage('data_view') },
	  },
	  {
	  	selector: '.column-button',
	  	content: "Click on the 'choose columns' button to show just the information you are interested in",
	  	action: ()=>{ props.setPage('data_view') },
	  }
	);

	if(props.showClippy){
		steps.push({
	  	selector: '.clippy',
	  	content: "Some sort of paperclip?",
		})
	}


	return <Tour
    steps={ steps }
    isOpen={ props.tourShowing }
    maskClassName="mask"
    className="helper"
    rounded={5}
    onRequestClose={ props.closeTour }
    disableInteraction={ true }
    startAt={0}
    disableDotsNavigation={ true }


  	update={props.currentPage+(props.showLayerPanel?'1':'0')+(props.showDataPanel?'1':'0')}
  	updateDelay={500}

  />
}


export default connect(

  (state, props)=>({
  	tourShowing:state.tourShowing,
  	showClippy:state.showClippy,
  	showDataPanel:state.showDataPanel,
  	showLayerPanel:state.showLayerPanel,
  	currentPage:state.currentPage,
  }),

  (dispatch, props)=>({
  	setCurrentLayer: (layer)=>{dispatch({type:'SET_CURRENT_LAYER', payload:layer})},
  	doToggle: ()=>{dispatch({type:'SHOW_PANEL', payload:props.toggle})},
  	setCurrentWard: (ward)=>{dispatch({type:'SET_CURRENT_WARD', payload:ward})},
  	closeTour:()=>{dispatch({type:'SET_TOUR_SHOWING', payload:false})},
  	setPanel: (panel, value)=>{dispatch({type:'SET_PANEL', payload:{panel,value}})},
  	setPage:(page)=>{dispatch({type:'SET_PAGE', payload:{page:page}})},
  	setLocation: (location)=>{dispatch({type:'SET_LOCATION', payload:location})},

  })

)(tour);

