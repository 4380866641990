
import { connect } from "react-redux";

import { motion } from "framer-motion"

import { fastTransition } from '../common.js'

const variants = {
  'visible': {
    opacity: 1,
    y: 0,
    //display: "block",
    //visibility:"visible",
    zIndex: 1,
  },
  'hidden': {
    opacity: 0,
    y: 10,
    zIndex: 0,
    transitionEnd: {
      //display: "none",
      //visibility:"hidden",
    },
  },
}


function MenuPage(props) {

	return (
		<motion.div key={props.page} className="page" initial={ false } variants={ variants } animate={ props.isSelected?'visible':'hidden' } transition={ fastTransition } >
			{props.children}
		</motion.div>
	)
}

export default connect(

  (state, props)=>({
    isSelected: state.currentPage === props.page,
  }),

  (dispatch, props)=>({

  })

)(MenuPage);