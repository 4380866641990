import { connect } from "react-redux";

import { motion, AnimateSharedLayout } from 'framer-motion'



function LayerPanel(props)
{
	function getClass(name)
	{
		var className = 'layer-button';
		if(name === props.currentLayer)
			className += ' layer-button-current';
		else if(name === props.hoveredLayer)
			className += ' layer-button-hovered';
		return className;
	}

	/*
	function getColor(name)
	{
		var color = '#FFF';
		if(name === props.currentLayer)
			color = '#00a69c';
		else if(name === props.hoveredLayer)
			color = '#FBFBFB';
		return color;
	}
	*/

	var timeline_tip = "<div class='tooltip-title'>Timeline Layer</div><div>This layer contains data for more than 1 point in time</div>";

	//Build layer info from layerGroups and layer data
	var stuff = props.layerGroups.map((group)=>(
		<div key={ group.name } className="layer-group">
			{ group.layers.map((layer, i)=>(
				<div key={ layer } className="layer-control">
					<div 
						className={ getClass(layer) }
						onClick={()=>{ if(props.layers[layer])props.setCurrentLayer(layer) }}
						onMouseEnter={()=>{ props.setHoveredLayer(layer) }}
						onMouseLeave={()=>{ props.setHoveredLayer(null) }} 
					>
						{ props.layers[layer]?props.layers[layer].name:layer }
					</div>
					<button data-html={true} data-tip={ "<div class='tooltip-title'>"+props.layers[layer].name+"</div><div>"+props.layers[layer].tooltip+"</div>" } data-for={"layer-tip"} className="info-button">i</button>
					{ props.layers[layer].timeline && false && (
						<button data-html={true} data-tip={ timeline_tip } data-for={"layer-tip"} class="layer-timeline-badge">t</button>
					) }					
				</div>
			))}
		</div>
	));




	//Determine which panel is open (based on layer selected)

	
	/*
	function getClass2(name)
	{
		if(name === props.currentLayer)
			return ' layer-current ';
		else if(name === props.hoveredLayer)
			return ' layer-hovered ';
		return ' ';
	}
	function oneSelected(items)
	{
		return items.includes(props.currentLayer)?' layer-open ':'';
	}
	*/	
	
	/*
	var stuff = props.layerGroups.map((group)=>(
		<motion.div
			key={ group.name } 
			className={"layer-parent"+getClass2(group.name)+oneSelected(group.layers)} 
			onClick={()=>props.setCurrentLayer(group.layers[0])}
		>
			<button 
				key={'title'} 
				className={"layer-child"+getClass2(group.layers[0])+" layer-child-main"}
			>
				{ props.layers[group.layers[0]].name }
			</button>

			<motion.div 
				initial={ false }
				animate={ oneSelected(group.layers)?'open':'collapsed' }
				className="layer-parent-content"
				variants={{
	        open: { height: "auto" },
	        collapsed: { height: 0 },
	      }}
				transition={fastTransition}
			>

			{ group.layers.length > 1 && (
			<div className="layer-parent-items">
			{
					group.layers.slice(1).map((layer)=>(

						<div key={ layer }>
						<button 
							key={ layer }
							className={"layer-child"+getClass2(layer)} 
							onClick={(e)=>{props.setCurrentLayer(layer);e.stopPropagation()}}
						>
							{ props.layers[layer].name }
						</button>
						</div>
					))

			}
			</div>
			)}

			{
					group.timeline && <CovidRocker />
			}


			</motion.div>

		</motion.div>
	));
	*/

//<div className="info-popup">{ props.layers[layer].tooltip }</div>

	/*
	<div style={{ paddingTop:'10px', }}>
		<h3>Time Period</h3>
		<CovidRocker />
	</div>
	*/



	return (
		<AnimateSharedLayout>
		<div className="data-layer">
			<h2>Data Layers</h2>
			{ stuff }

			<motion.div className="layer-outlets" >
				<div key="header" className="layer-outlets-item">
					<h2 style={{ flexBasis:"150px",marginBottom:"5px",marginRight:"auto",marginTop:"0px" }} >Food Outlets</h2>
					<div style={{ flexBasis:"80px",marginBottom:"5px", textAlign:'center', fontSize:'14px', fontWeight:'normal', lineHeight:'18px' }}>Show</div>
				</div>
				<div className="layer-outlets-item">
					<div key="showFoodBanks" className="outlet-icon bank-icon" /><div className="layer-outlets-name" >Food Banks</div>
					<button data-html={true} data-tip={ "<div class='tooltip-title'>Food Banks</div><div>Food banks and centres provide emergency food and support to help people resolve the crises they face (The Trussell Trust, 2020).</div>" } data-for={"layer-tip"} className="info-button">i</button>
					<input checked={ props.showFoodBanks } onChange={ ()=>props.setShowFoodBanks(!props.showFoodBanks)  } type='checkbox' />
				</div>
				<div className="layer-outlets-item">
					<div key="showFoodStores" className="outlet-icon store-icon" /><div className="layer-outlets-name" >Food Stores</div>
					<button data-html={true} data-tip={ "<div class='tooltip-title'>Food Stores</div><div>This layer provides information on the location of supermarkets across England, as documented by the Food Standards Agency in their process of providing food hygiene ratings to businesses (FSA, 2020).</div>" } data-for={"layer-tip"} className="info-button">i</button>
					<input checked={ props.showFoodStores } onChange={ ()=>props.setShowFoodStores(!props.showFoodStores) } type='checkbox' />
				</div>
				<div className="layer-outlets-item">
					<div key="showFoodRestaurants" className="outlet-icon restaurant-icon" /><div className="layer-outlets-name" >Restaurants & Takeaways</div>
					<button data-html={true} data-tip={ "<div class='tooltip-title'>Restaurants & Takeaways</div><div>This layer provides information on the location of restaurants, cafes and canteens, and takeaways and sandwich shops across England, as documented by the Food Standards Agency in their process of providing food hygiene ratings to businesses (FSA, 2020).</div>" } data-for={"layer-tip"} className="info-button">i</button>
					<input checked={ props.showFoodRestaurants } onChange={ ()=>props.setShowFoodRestaurants(!props.showFoodRestaurants) } type='checkbox' />
				</div>
				<div className="layer-outlets-item">
					<div key="showSchools" className="outlet-icon school-icon" /><div className="layer-outlets-name" >Free School Meals</div>
					<button data-html={true} data-tip={ "<div class='tooltip-title'>Free School Meals</div><div>"+props.layers.fsm_eligible_taking.tooltip+"</div>" } data-for={"layer-tip"} className="info-button">i</button>
					<input checked={ props.showSchools } onChange={ ()=>props.setShowSchools(!props.showSchools) } type='checkbox' />
				</div>

				<div className="layer-outlets-item" style={{ marginTop:'30px' }}>
					<div key="showWardLabels" className="outlet-icon ward-icon" /><div className="layer-outlets-name" >Ward Names</div>
					<input checked={ props.showWardLabels } onChange={ ()=>props.setShowWardLabels(!props.showWardLabels) } type='checkbox' />
				</div>

				<div className="layer-outlets-item">
					<div key="showWardLabels" className="outlet-icon ward-welfare-icon" /><div className="layer-outlets-name" >Ward Welfare (now)</div>
					<button data-html={true} data-tip={ "<div class='tooltip-title'>Ward Welfare</div><div>"+props.layers.ward_welfare.tooltip+"</div>" } data-for={"layer-tip"} className="info-button">i</button>
					<input checked={ props.showWardWelfare } onChange={ ()=>props.setShowWardWelfare(!props.showWardWelfare) } type='checkbox' />
				</div>
				

			</motion.div>
		</div>
		</AnimateSharedLayout>

	)
}

export default connect(

  (state, props)=>({
  	layers:state.layers,
    currentLayer:state.currentLayer,
    hoveredLayer:state.hoveredLayer,
    layerGroups:state.layerGroups,
    wardData:state.wardData,
    currentWard:state.currentWard,
    showFoodBanks:state.showFoodBanks,
    showFoodStores:state.showFoodStores,
    showFoodRestaurants:state.showFoodRestaurants,
    showSchools:state.showSchools,
    showWardLabels:state.showWardLabels,
    showWardWelfare:state.showWardWelfare,
  }),

  (dispatch, props)=>({
  	setCurrentLayer: (layer)=>{
  		dispatch({type:'SET_CURRENT_LAYER', payload:layer});
  	},
  	setHoveredLayer: (layer)=>{
  		dispatch({type:'SET_HOVERED_LAYER', payload:layer});
  	},
  	setShowFoodStores:(show)=>{
  		dispatch({ type:'SET_SHOW_FOOD_STORES', payload:show });
  	},
  	setShowFoodBanks:(show)=>{
  		dispatch({ type:'SET_SHOW_FOOD_BANKS', payload:show });
  	},
  	setShowFoodRestaurants:(show)=>{
  		dispatch({ type:'SET_SHOW_FOOD_RESTAURANTS', payload:show });
  	},
  	setShowSchools:(show)=>{
  		dispatch({ type:'SET_SHOW_SCHOOLS', payload:show });
  	},
  	setShowWardLabels:(show)=>{
  		dispatch({ type:'SET_SHOW_WARD_LABELS', payload:show });
  	},
  	setShowWardWelfare:(show)=>{
  		dispatch({ type:'SET_SHOW_WARD_WELFARE', payload:show})
  	}

  })

)(LayerPanel);