
import { AnimateSharedLayout, AnimatePresence, motion } from "framer-motion"

import axios from "axios"


import MenuTab from './MenuTab.js'
import MenuPage from './MenuPage.js'
import MapView from './MapView.js'
import SidePanel from './SidePanel.js'
import SelectionPanel from './SelectionPanel.js'
import LayerPanel from './LayerPanel.js'
import DataView from './DataView.js'
import DataExport from './DataExport.js'
import DataCollection from './DataCollection.js'
import LoginPage from './LoginPage.js'
import ChartsView from './ChartsView.js'

import MapTitle from './MapTitle.js'
import MapLegend from './MapLegend.js'

import TestPage from './TestPage.js'

import {useEffect} from 'react'
import {connect}  from 'react-redux'
import ReactTooltip from "react-tooltip";

import { fastTransition } from '../common.js'


function csv_to_json(data)
{
	//Convert csv to json
	var lines = data.split("\r\n")
	var headers = lines[0].split(',')
	var json = {}
	lines.slice(1).forEach((line)=>{
		var item = {};
		line.split(',').forEach((field,i)=>{
			field = field.trim()
			if(!isNaN(field))field = parseFloat(field)
			item[headers[i]] = field
		})
		json[item[headers[0]]] = item
	})
	return json;
}

function process_lsoa_data(imd_data, ww_data, setInitialData)
{
	/*
	LSOA data is converted from CSV to JSON format (fields converted to properties)
	Ward level data is generated from it (using population weighted averages)
	LSOA and Ward ranking is also calculated for each field

	This adds roughly 1 second of pre-processing, but allows all LSOA data to be sent 
	in a single 1mb compressed file (so long as the csv is statically compressed by
	the server), and then gives flexibility client side to use in various ways

	Note that this provides data for all LSOAs and Wards in England

	Calculating the LSOA ranking for each field is the slowest part

	It would be considerably faster to have all the lsoa data embedded in the 
	map tiles (i.e. uploaded to mapbox as a data source), and then just download 
	pre-calc data	for the current local authority, which becomes a relatively trivial 
	file download with no pre-processing
	*/

	var timings = []
	var t0 = performance.now();
	timings.push(performance.now()-t0)

	/*
	//Convert csv to json
	var lines = data.split("\r\n")
	var headers = lines[0].split(',')
	var lsoas = {}
	lines.slice(1).forEach((line)=>{
		var lsoa = {};
		line.split(',').forEach((field,i)=>{
			field = field.trim()
			if(!isNaN(field))field = parseFloat(field)
			lsoa[headers[i]] = field
		})
		lsoas[lsoa.lsoa_code] = lsoa
	})*/

	//Convert csv to json
	var lsoas = csv_to_json(imd_data)

	var ww_lsoas = csv_to_json(ww_data);

	//console.log({lsoas})
	//return;


	timings.push(performance.now()-t0)

	//Get headers that we need to sum, and the other ones that we do weighted population averages and ranking with
	//var sums = headers.slice(3, 7);
	//var values = headers.slice(7);
	var copy_fields = [
		'lsoa_code',
		'WD19CD',
		'LAD19CD'
	]
	var sum_fields = [
		'mid_2019_population',
		'restaurants',
		'takeaways',
		'supermarkets',
		'food_retailers'
	];
	var multiply_fields = [
		'imd_score',	
		'income_score',
		'employment_score',
		'edu_skills_training_score',	
		'health_disability_score',	
		'crime_score',	
		'barriers_housing_services_score',
		'living_environment_score'
	];

	var ww_sum_fields = [
		'population_2019',
		'population_2020',
		'hb_claimants_2019',
		'hb_claimants_2020',
		'uc_claimants_2019',	
		'uc_claimants_2020',
		'fsm_eligible_taking_2019',
		'fsm_eligible_taking_2020',	
		'pupil_population_2019',
		'pupil_population_2020'	
	];
	var ww_multiply_fields = [
		'ward_welfare_2019',
		'ward_welfare_2020',
		'fid_2019',
		'fid_2020'	
	];

	//Combine all field names, to initialise ward data with
	var headers = [...copy_fields, ...sum_fields, ...multiply_fields, ...ww_sum_fields, ...ww_multiply_fields];

	//For each record (LSOA)
	var wards = {};
	for(let lsoa_name in lsoas){
		let lsoa = lsoas[lsoa_name];
		let ward_name = lsoa.WD19CD;


		//If ward not known, create new object to store info
		let ward = wards[ward_name];

		if(!ward){
			ward = wards[ward_name] = {};
			headers.forEach((header)=>ward[header] = 0)

			//Copy over stats
			copy_fields.forEach((field)=>{
				ward[field] = lsoa[field]
			})		
		}

		//Aggregate population, and population*stat for all imd stats
		sum_fields.forEach((field)=>{
			ward[field] += lsoa[field];
		})
		multiply_fields.forEach((field)=>{

			//console.log(lsoa[field])

			ward[field] += lsoa[field]*lsoa.mid_2019_population;
		})
	}

	var test1 = [];

	//For each ww record (also an LSOA)
	for(let lsoa_name in ww_lsoas){
		let ww_lsoa = ww_lsoas[lsoa_name]
		let lsoa = lsoas[lsoa_name]

		let ward_name = lsoa.WD19CD;
		let ward = wards[ward_name];
		//must always be a ward at this point
		if(!ward)console.error('Ward not found');

		//Copy over stats to lsoa record
		for(var field in ww_lsoa){
			lsoa[field] = ww_lsoa[field]
		}

		//Hack - remove fid from outside of havering
		//console.log(lsoa)
		if(lsoa.LAD19CD !== "E09000016"){
			lsoa.fid_2019 = NaN;
			lsoa.fid_2020 = NaN;
		}
		else{
			lsoa.fid_2019 = Math.round(100*lsoa.fid_2019);
			lsoa.fid_2020 = Math.round(100*lsoa.fid_2020);			
			//console.log(lsoa.fid_2019);
			//console.log(lsoa.fid_2020);
			test1.push(lsoa.fid_2019)
			test1.push(lsoa.fid_2020)
			//test1.push(lsoa.fid_2020-lsoa.fid_2019)
		}



		//Aggregate population, and population*stat for all imd stats
		ww_sum_fields.forEach((field)=>{
			if(lsoa[field])
				ward[field] += lsoa[field];
		})
		ww_multiply_fields.forEach((field)=>{


			if(lsoa[field]){


				ward[field] += lsoa[field]*lsoa.mid_2019_population;
			}
		})

		//lsoa['hb_claimants_2019'] = 100*lsoa['hb_claimants_2019']/lsoa['population_2019'];

				//Also correct ww stats

		//lsoa['fid_2019'] = 100*lsoa['fid_2019']/lsoa.mid_2019_population;
		//lsoa['fid_2020'] = 100*lsoa['fid_2020']/lsoa.mid_2019_population;
		lsoa['fid_diff'] = lsoa['fid_2020'] - lsoa['fid_2019'];

		//lsoa['ward_welfare_2019'] = 100*lsoa['ward_welfare_2019']/lsoa['population_2019'];
		//lsoa['ward_welfare_2020'] = 100*lsoa['ward_welfare_2020']/lsoa['population_2020'];
		lsoa['ward_welfare_diff'] = lsoa['ward_welfare_2020'] - lsoa['ward_welfare_2019'];

		lsoa['hb_claimants_2019'] = 100*lsoa['hb_claimants_2019']/lsoa['population_2019'];
		lsoa['hb_claimants_2020'] = 100*lsoa['hb_claimants_2020']/lsoa['population_2020'];
		lsoa['hb_claimants_diff'] = lsoa['hb_claimants_2020'] - lsoa['hb_claimants_2019'];

		lsoa['uc_claimants_2019'] = 100*lsoa['uc_claimants_2019']/lsoa['population_2019'];
		lsoa['uc_claimants_2020'] = 100*lsoa['uc_claimants_2020']/lsoa['population_2020'];
		lsoa['uc_claimants_diff'] = lsoa['uc_claimants_2020'] - lsoa['uc_claimants_2019'];
		
		lsoa['fsm_eligible_taking_2019'] = 100*lsoa['fsm_eligible_taking_2019']/lsoa['pupil_population_2019'];
		lsoa['fsm_eligible_taking_2020'] = 100*lsoa['fsm_eligible_taking_2020']/lsoa['pupil_population_2020'];
		lsoa['fsm_eligible_taking_diff'] = lsoa['fsm_eligible_taking_2020'] - lsoa['fsm_eligible_taking_2019'];
	}

	test1.sort();


	timings.push(performance.now()-t0)

	//For each ward
	for(let ward_name in wards){
		let ward = wards[ward_name];

		//Divide imd stats by population to make comparible to lsoa stats
		multiply_fields.forEach((header)=>{
			ward[header] /= ward.mid_2019_population;
		})

		//ww_multiply_fields.forEach((header)=>{
		//	ward[header] /= ward.mid_2019_population;
		//})



		//Also correct ww stats
		ward['fid_2019'] = Math.round(ward['fid_2019']/ward.mid_2019_population)
		ward['fid_2020'] = Math.round(ward['fid_2020']/ward.mid_2019_population)


		ward['ward_welfare_2019'] = ward['ward_welfare_2019']/ward['population_2019'];
		ward['ward_welfare_2020'] = ward['ward_welfare_2020']/ward['population_2020'];

		ward['hb_claimants_2019'] = 100*ward['hb_claimants_2019']/ward['population_2019'];
		ward['hb_claimants_2020'] = 100*ward['hb_claimants_2020']/ward['population_2020'];

		ward['uc_claimants_2019'] = 100*ward['uc_claimants_2019']/ward['population_2019'];
		ward['uc_claimants_2020'] = 100*ward['uc_claimants_2020']/ward['population_2020'];

		ward['fsm_eligible_taking_2020'] = 100*ward['fsm_eligible_taking_2020']/ward['pupil_population_2019'];
		ward['fsm_eligible_taking_2019'] = 100*ward['fsm_eligible_taking_2019']/ward['pupil_population_2020'];

		//Create free school meal percentage
		//ward.fsm_percent = 100*ward.lsoa_pupils_taking_fsm / ward.lsoa_pupils;
	}

	timings.push(performance.now()-t0)

	//For each field
	const lsoa_names = Object.keys(lsoas);
	var ward_names = Object.keys(wards);

	const rank_fields = [...multiply_fields, ...ww_sum_fields];

	rank_fields.forEach((field)=>{

		//For each lsoa, calculate and assign rank field
		let testers = lsoa_names.map(name=>({name, value:lsoas[name][field]}));
		testers.sort((a,b)=>b.value-a.value);
		testers.forEach((val, i)=>lsoas[val.name][field+'_rank'] = i)		

		//For each ward, calculate and assign rank field
		testers = ward_names.map(name=>({name, value:wards[name][field]}));
		testers.sort((a,b)=>b.value-a.value);
		testers.forEach((val, i)=>wards[val.name][field+'_rank'] = i)		
	})

	timings.push(performance.now()-t0)


	setInitialData({
		lsoaData: lsoas,
		wardData: wards,
	})
}

function App(props) {

	//When the app starts up
	useEffect(()=>{

		/*
		//Get data
		axios.get('/data/lsoa_data.csv').then((response)=>{

			//Process it
			process_lsoa_data(response.data, props.setInitialData);

		})
		*/

		const requests = [
			axios.get('/data/lsoa_data.csv'),
			axios.get('/data/lsoa_london_data.csv')
		]

		axios.all(requests).then((responses)=>{
			process_lsoa_data(responses[0].data, responses[1].data, props.setInitialData);				
		})

	}, [props.setInitialData])


	var loggedIn = !!props.user;

	return (
		<AnimatePresence>

			{(!loggedIn) && (
				<LoginPage />
			)}

			{(loggedIn) && (

				<motion.div key="loggedin-content" className="logged-in" style={{ height:'100%', width: '100%', position: 'absolute'}}
					initial={{ opacity:0, scale:1.1 }} animate={{ opacity:1, scale:1 }} exit={{ opacity:0, scale:1.1 }} transition={ fastTransition }
				>
					<div className="header">
						<div className="content">
							<div className="olio-logo" />
							<div className="nlab-logo" />
							<div style={{ padding: '20px 0px', marginLeft:'auto' }}>
								<span className="login-blurb" onClick={ props.logout }>Welcome { props.user.username }, Havering LA</span>
								<button className="logout-button" onClick={ props.logout }>log out</button>
							</div>
						</div>
					</div>
					<div className="menu-bar">
						<div className="content">
							<AnimateSharedLayout>
								<MenuTab page="map">MAP</MenuTab>
								<MenuTab page="data_view">DATA VIEW</MenuTab>
								<MenuTab page="charts">CHARTS</MenuTab>
								<MenuTab page="export">EXPORT</MenuTab>
								<MenuTab page="data_collection">FOOD OUTLETS</MenuTab>
								<MenuTab page="definitions">DEFINITIONS</MenuTab>
							</AnimateSharedLayout>
						</div>
					</div>
					<div className="main-area">
						<MenuPage page="map">
							<MapView />
							<MapLegend />
							<MapTitle />
							<SidePanel className="layer-panel" toggle="showLayerPanel" buttonName="layers" buttonIcon="leftarrow">
								<LayerPanel />
							</SidePanel>
							<SidePanel className="data-panel" toggle="showDataPanel" buttonName="insight" buttonIcon="rightarrow">
								<SelectionPanel />
							</SidePanel>
						</MenuPage>
						<MenuPage page="data_view">
							<DataView />
						</MenuPage>
						<MenuPage page="charts">
							<ChartsView />
						</MenuPage>
						<MenuPage page ="export">
							<DataExport />
						</MenuPage>
						<MenuPage page="data_collection">
							<DataCollection />
						</MenuPage>
						<MenuPage page="definitions">
							<TestPage />
						</MenuPage>
					</div>


					<ReactTooltip id={"layer-tip"} place="right" effect="solid" border={true} borderColor="rgb(220,220,220)" backgroundColor="white" className="info-tooltip" type="light" />
				</motion.div>
			)}

		</AnimatePresence>
	);
}


export default connect(

  (state, props)=>({
  	wards:state.wards,
  	user:state.user,
  }),

  (dispatch, props)=>({
  	setInitialData:(data)=>{ dispatch({type:'SET_INITIAL_DATA', payload:data}) },

  	setCurrentLayer: (layer)=>{
  		dispatch({type:'SET_CURRENT_LAYER', payload:layer});
  	},
  	doToggle: ()=>{
  		dispatch({type:'SHOW_PANEL', payload:props.toggle})
  	},
  	setCurrentWard: (ward)=>{dispatch({type:'SET_CURRENT_WARD', payload:ward})},

  	logout: (user)=>{
  		dispatch({type:'SET_CURRENT_USER', payload:null});
  	},

  })

)(App);

