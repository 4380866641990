import { connect } from "react-redux";


function DataExport(props)
{

	const type_fields = {
		fid:[
			'WD19CD',
			'fid_2019', 'fid_2020'
		],
		ww:[
			'WD19CD', 
			'ward_welfare_2019', 'ward_welfare_2020', 
			'uc_claimants_2019', 'uc_claimants_2020', 
			'hb_claimants_2019', 'hb_claimants_2020', 
			'fsm_eligible_taking_2019', 'fsm_eligible_taking_2020'
		],		
		imd:[
			'WD19CD',
			'imd_score',
			'income_score',
			'edu_skills_training_score',
			'health_disability_score',
			'crime_score',
			'barriers_housing_services_score',
			'living_environment_score'
		],
	}

	function json_to_csv(json)
	{
		let lines = [];
		let first = true;
		for(let name in json){
			let item = json[name]

			//Add header
			if(first){
				let fields = []
				for(let field in item){
					fields.push(field)
				}
				lines.push(fields.join(','))
				first = false;
			}

			//Add item
			let fields = [];
			for(let field in item){
				fields.push(item[field])
			}
			console.log(item);
			lines.push(fields.join(','))
		}

		return lines.join("\r\n");
	}

	function download(type)
	{
		const filename = type+".csv"

		//Build output, filtered for just requested fields, and for wards in current LAD
		const fields = type_fields[type]
		var output = {}
		for(let name in props.wardData){
			let ward = props.wardData[name]
			//console.log({ward})

			if(ward.LAD19CD !== props.currentLAD)continue;

			let item = {}
			fields.forEach((field)=>item[field] = ward[field])
			output[name] = item
		}

		//Convert to csv
		const csv = json_to_csv(output)

		//var text = JSON.stringify(props.wardData);

		var element = document.createElement('a');
	  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv));
	  element.setAttribute('download', filename);
	  element.style.display = 'none';
	  document.body.appendChild(element);
	  element.click();
	  document.body.removeChild(element);
	}

	return (
		<div className="content">
			<div className="export">
				<h1>Data export</h1>
				<div className="blurb">The data for Havering LA is available as a CSV download</div>
				<h2>Food Insecurity Detected</h2>
				<button className="file-download-button" onClick={ ()=>download('fid') } >Download</button>
				<h2>Ward Welfare</h2>
				<button className="file-download-button" onClick={ ()=>download('ww') } >Download</button>
				<h2>Index of Multiple Deprivation</h2>
				<button className="file-download-button" onClick={ ()=>download('imd') } >Download</button>
			</div>
		</div>
	)
}

export default connect(


  (state, props)=>({
		wardData:state.wardData,
		currentLAD:state.currentLAD,
  }),

  (dispatch, props)=>({

  })

)(DataExport);