
import {useState, useEffect} from 'react'

import {connect} from 'react-redux'
import {motion, AnimatePresence} from 'framer-motion'

import { fastTransition } from '../common.js'

import axios from "axios"


function LoginPage(props)
{
	const [user, setUser] = useState({username:'guest', password:'guest'})

	const [failed, setFailed] = useState(false)

	function submit(e)
	{
		e.preventDefault()

		//Get the users file
		axios.get('/data/users.json').then((response)=>{
			const logins = response.data

			//If not found, then set failed state
			if(!logins[user.username] || logins[user.username] !== user.password){
				setFailed(true)
				return
			}

			//Else set user an unset failed state
			setFailed(false)
			props.setUser(user);
		})
	}


	return (
		<motion.div key="login-page" className="login-page" initial={{ opacity:0, scale:1.2 }} animate={{ opacity:1, scale:1 }} exit={{ opacity:0, scale:1.2 }} transition={ fastTransition }>
			<AnimatePresence>
			<motion.div key="logo" layout className="olio-logo" />
			{ failed && (
				<motion.div key="error" layout className="olio-login-error" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={ fastTransition }>
					<button className="olio-login-error-close" onClick={ ()=>setFailed(false) } />
					Sorry, those are not valid login details
				</motion.div>
			)}
			<motion.div key="form" layout className="login-form">
				<form onSubmit={submit} >
					<div>
						<input placeholder="username" value={ user.username }  onChange={ (e)=>setUser({...user, username:e.target.value}) } />
					</div>
					<div>
						<input placeholder="password" type="password" value={ user.password } onChange={ (e)=>setUser({...user, password:e.target.value}) } />
					</div>
					<div>
						<input type="submit" value="log in" />
					</div>
				</form>
			</motion.div>
			</AnimatePresence>

		</motion.div>
	)
}

//export default LoginPage


export default connect(

  (state, props)=>({

  }),

  (dispatch, props)=>({
  	setUser: (user)=>{
  		dispatch({type:'SET_CURRENT_USER', payload:user});
  	},
  })

)(LoginPage);