import { connect } from "react-redux"

import { motion, AnimatePresence } from "framer-motion"

import { fastTransition, traffic_light_color } from '../common.js'

function formatNumber(num) {
  
  var number = num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')

  return <span>{ number }</span>
}

function formatLight(num){

	/*
	var light = 'green';
	if(num < 300)
		light = 'red'
	else if(num < 500)
   	light = 'amber'
	*/
  return <div className={ "cell-red" } style={{backgroundColor:traffic_light_color(num)}} />
}


function DataView(props)
{


	function headerClick(column)
	{
		if(column === props.sortColumn){
			props.setSort(column, !props.sortAscending);
		}
		else{
			props.setSort(column, true);
		}
	}

	/*
	function downloadClick()
	{
		//Join headers and content data
		const output = [visible_layers, ...data];

		//Convert to just the values, join with tabs and carriage returns to make csv
		const text = output.map(row=>row.map(cell=>cell.value).join(",")).join("\n");

		//Set as href content for the anchor, and click it to initiate download
		var ref = download_ref.current;
		ref.setAttribute('href', "data:text/plain;charset=utf-8,"+encodeURIComponent(text))
		ref.click();
	}
	*/

	//Get all titles, sort index, and layers list

	//All layers, is what is shown in the drop down menu
	let all_layers = [];

	//Visible layers, are the ones that are shown in the grid
	let visible_layers = [{name:'Name', value:'ward-name'}];

	//For each layer group
	props.layerGroups.forEach((group,i)=>{

		//If not the first, then add separator
		if(i){
			all_layers.push({name:'-'});
		}

		//For each layer
		group.layers.forEach(name=>{
			let layer = props.layers[name];


			//Get if index
			//if(name === props.sortColumn)sortIndex = i;
			//++i;

			//Add to drop down list
			all_layers.push({name:layer.name, value:name, layer:layer});

			//No further if disabled
			if(props.disabledColumns.includes(name))return;

			//Add layer (or layers) to visible
			if(layer.timeline){
				visible_layers.push({name:layer.name+' (pre-covid)', value:name+'_2019', layer:layer});
				visible_layers.push({name:layer.name+' (now)', value:name+'_2020', layer:layer});
			}
			else{
				visible_layers.push({name:layer.name, value:name, layer:layer});
			}		

		})
	})

	console.log({visible_layers})

	//Get all ward data
	let wards = {};

	let sortIndex = 0;

	//For each visible layer
	visible_layers.forEach(({name, value, layer}, i)=>{
		if(i===0)return
		const layer_name = value

		if(layer_name === props.sortColumn)sortIndex = i

		//For each ward that exists
		for(let ward_name in props.wardData){
			var ward = props.wardData[ward_name];
			if(!props.wards[ward_name])continue;

			//Add data to ward value list
			if(!wards[ward_name])wards[ward_name] = [{value:props.wards[ward_name].name, display:props.wards[ward_name].name}];
			if(!(layer_name in ward)){
				console.log("issue", layer_name, ward)
				wards[ward_name].push({value:0, display:'(unknown)'});
			}

			else if(layer.format === '?%')
				wards[ward_name].push({value:ward[layer_name], display:layer.format.replace('?', Math.round(ward[layer_name]))});
			else if(layer.type === 'colour')
				wards[ward_name].push({value:ward[layer_name], display:formatLight(ward[layer_name])});
			else
				wards[ward_name].push({value:ward[layer_name], display:formatNumber(ward[layer_name])});
				
				//wards[ward_name].push({value:ward[layer_name], display:Math.round(ward[layer_name])});

			/*
			else if(layer_name == 'fsm_percent')
				wards[ward_name].push({value:ward[layer_name], display:Math.round(ward[layer_name])+'%'});
			else if(layer_name == 'tipping-point')
				wards[ward_name].push({value:ward[layer_name], display:formatLight(ward[layer_name])});
			else
				wards[ward_name].push({value:ward[layer_name], display:formatNumber(Math.round(ward[layer_name]))});
			*/
		}
	})

	//Convert ward object to array, and sort
	let data = Object.values(wards);
	data.sort((a,b)=>{
		if(a[sortIndex].value<b[sortIndex].value)return -1;
		else if(a[sortIndex].value>b[sortIndex].value)return 1;
		return 0;
	})
	if(!props.sortAscending){
		data.reverse();
	}

/*
						<CovidRocker />
						<button className="download-button" onClick={ downloadClick } >Download</button>
*/

	return (
		<div onClick={()=>{ if(props.showMenu)props.doShowMenu(false) }}>

			<div className="content">
				<div className="data"> 
					<div className="data-view-top">

						<h1>Data View</h1>

						<div className="column-button-container">
							<div className="column-button" onClick={ ()=>props.doShowMenu(!props.showMenu) }>
								choose columns <motion.div className="arrow-vertical arrow-down" initial={false} animate={{ rotate:props.showMenu?'180deg':'0deg' }} transition={ fastTransition } ></motion.div>
							</div>
							<AnimatePresence>
								{ props.showMenu && (
								<motion.div key="column-menu" className="column-button-menu" 
										initial={{ y:-5, opacity: 0 }}
			        			animate={{ y:0, opacity: 1 }}
			        			exit={{ y:-5, opacity: 0 }}
			        			transition={ fastTransition }
			        			onClick={(e)=>{ e.stopPropagation() }}
			        		>
									<div className="blurb">
										Choose columns to display
									</div>
									<div className="column-button-items">
									{
										all_layers.map((layer, index)=>{

											if(layer.name === '-'){
												return (
													<div key={ index } className="column-button-separator" />
												)
											}
											return (
												<div key={ index } className="column-button-item">
													<span>{ layer.name }</span>
													<input type="checkbox" onChange={ ()=>props.showColumn(layer.value, !props.disabledColumns.includes(layer.value)) } checked={ !props.disabledColumns.includes(layer.value) } />
												</div>
											)
											
										})
									}	
									</div>
								</motion.div>
								)}			
							</AnimatePresence>
						</div>

					</div>

					<div className="grid">

						<div key="header" className="row row-header"> { visible_layers.map((val,index) => (
							<span key={ index } onClick={ ()=>headerClick(val.value) } className={ index?'cell':'cell cell-header' }>
								{ val.name }
								{ val.value === props.sortColumn &&
									<div className={ props.sortAscending?"sort-arrow":"sort-arrow  sort-arrow-descending" }></div>
								}
								{ index > 0 && (
									<button data-html={true} data-tip={ "<div class='tooltip-title'>"+val.layer.name+"</div><div>"+val.layer.tooltip+"</div>" } data-for={"layer-tip"} className="info-button">i</button>
								)}
							</span>
						))}</div>

						{ 
							data.map((ward, index)=>(
								<div className="row" key={index}> 
									{ward.map((val,index)=>( 
										<span key={ index } className={ index?'cell':'cell cell-header' }>{ val.display }</span> )
									)} 
								</div>
							))
						}

					</div>
				</div>
			</div>
		</div>
	)


}

//export default DataView


export default connect(

  (state, props)=>({
    //currentWard:state.currentWard,
    //hoveredWard:state.hoveredWard,
    layers:state.layers,
    layerGroups:state.layerGroups,
    //currentLayer:state.currentLayer,
    //hoveredLayer:state.hoveredLayer,
    sortColumn:state.dataGrid.sortColumn,
    sortAscending:state.dataGrid.sortAscending,
    disabledColumns:state.dataGrid.disabledColumns,
    showMenu:state.dataGrid.showMenu,

    wardData:state.wardData,
    lsoaData:state.lsoaData,
    wards:state.wards,
    currentTime:state.currentTime,
  }),

  (dispatch, props)=>({
  	setSort:(column,ascending)=>{ dispatch({type:'SET_DATAGRID_SORT', payload:{column,ascending}}) },
  	showColumn:(column,show)=>{ dispatch({type:'SHOW_DATAGRID_COLUMN', payload:{column, show}}) },
  	doShowMenu:(show)=>{ dispatch({type:'SHOW_DATAGRID_MENU', payload:show})},
  	setCurrentTime:(time)=>{ dispatch({type:'SET_CURRENT_TIME', payload:time})}
  })

)(DataView);