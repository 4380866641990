import { connect } from "react-redux"

import Rocker from "./Rocker.js"

function CovidRocker({currentTime, setCurrentTime})
{
	return (
		<Rocker options={[
				{value:'2019', label:'pre-covid'}, 
				{value:'diff', label:'change'}, 
				{value:'2020', label:'now'}
			]} 
			value={ currentTime } 
			onChange={ setCurrentTime }  
		/>
	)
}

export default connect(

  (state, props)=>({
    currentTime:state.currentTime,
  }),
  (dispatch, props)=>({
  	setCurrentTime:(time)=>{ dispatch({type:'SET_CURRENT_TIME', payload:time})}
  })

)(CovidRocker);