import { motion } from "framer-motion"

import { fastTransition } from '../common.js'

export default function Arrow({direction, style={}, ...props})
{
	var common = {shapeRendering:'auto', strokeWidth:5, transitionEnd: { shapeRendering: "crispEdges" }};

	var variants = {
		up:{d:"M50 2.5L50 2.5L97.5 50L70 50L70 97.5L50 97.5L30 97.5L30 50L2.5 50Z", fill:"#E00", stroke:'#B00', ...common},
		down:{d:"M50 2.5L50 2.5L97.5 50L70 50L70 97.5L50 97.5L30 97.5L30 50L2.5 50Z", fill:"#0C0", stroke:'#090', ...common},
		same:{d:"M50 2.5L50 2.5L65 2.5L65 50L65 97.5L50 97.5L35 97.5L35 50L35 2.5Z", fill:"#AAA", stroke:'#999', ...common}
	}

	var tooltip = {
		up:"<div>Indicates an <b>increase</b> in this value between pre-covid and now</div>",
		down:"<div>Indicates a <b>decrease</b> in this value between pre-covid and now</div>",
		same:"<div>Indicates <b>no significant change</b> in this value between pre-covid and now</div>"
	}

	var variants2 = {
		up:{rotate:0},
		down:{rotate:180},
		same:{rotate:90}
	}

	//shape-rendering =" crispEdges "

	style = { width:'1em', height:'1em', ...style };

	console.log("DIRECTION", direction)

	return (
		<motion.div
			initial={ false }
			variants={ variants2 }
			animate={ direction }
			transition={ fastTransition }
			style={{ display:'inline-block', width:'1em', height:'1em', position:'relative', ...style }}
			{...props}
			>

			<motion.svg 
				xmlns="http://www.w3.org/2000/svg" 
				viewBox="0 0 100 100" 
				style={{ position:'absolute', 'left':0, 'top':0, width:'100%', height:'100%' }} 

				data-html={true} 
				data-tip={ tooltip[direction] } 
				data-for="layer-tip"
				data-place="bottom"

			>
				<motion.path variants={ variants } initial={ false } animate={ direction } transition={ fastTransition } />
			</motion.svg>
		</motion.div>
	)
}