//import {useClippy} from 'use-clippy-now'

import clippyjs from "clippyjs";


import {useEffect, useState, useRef} from 'react'
import {connect} from 'react-redux'

const styles = `
.clippy,
.clippy-balloon {
  position: fixed;
  z-index: 1000;
  cursor: pointer;
}
.clippy-balloon {
  background: #ffc;
  color: black;
  padding: 8px;
  border: 1px solid black;
  border-radius: 5px;
}
.clippy-content {
  max-width: 200px;
  min-width: 120px;
  font-family: 'Microsoft Sans', sans-serif;
  font-size: 10pt;
  line-height: 20px;
}
.clippy-tip {
  width: 10px;
  height: 16px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAgCAMAAAAlvKiEAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAAlQTFRF///MAAAA////52QwgAAAAAN0Uk5T//8A18oNQQAAAGxJREFUeNqs0kEOwCAIRFHn3//QTUU6xMyyxii+jQosrTPkyPEM6IN3FtzIRk1U4dFeKWQiH6pRRowMVKEmvronEynkwj0uZJgR22+YLopPSo9P34wJSamLSU7lSIWLJU7NkNomNlhqxUeAAQC+TQLZyEuJBwAAAABJRU5ErkJggg==)
    no-repeat;
  position: absolute;
}
.clippy-top-left .clippy-tip {
  top: 100%;
  margin-top: 0px;
  left: 100%;
  margin-left: -50px;
}
.clippy-top-right .clippy-tip {
  top: 100%;
  margin-top: 0px;
  left: 0;
  margin-left: 50px;
  background-position: -10px 0;
}
.clippy-bottom-right .clippy-tip {
  top: 0;
  margin-top: -16px;
  left: 0;
  margin-left: 50px;
  background-position: -10px -16px;
}
.clippy-bottom-left .clippy-tip {
  top: 0;
  margin-top: -16px;
  left: 100%;
  margin-left: -50px;
  background-position: 0 -16px;
}
`;



function Clippy(props)
{
	console.log('test', JSON.stringify(props.currentLayer))

	//Clippy ref
	const [clippy, setClippy] = useState(null);


	//Previous props
	const oldPropsRef = useRef(props)
	const oldProps = oldPropsRef.current
	oldPropsRef.current = {...props}

	console.log('new', JSON.stringify(props.currentLayer), 'old', JSON.stringify(oldProps.currentLayer))
	//console.log("same", props === oldProps)

	//On first time clippy is enabled
	useEffect(() => {

		if(props.showClippy){

			//Ignore if already loaded
			if(clippy){
				setTimeout(()=>{
					clippy.show()
					clippy.speak("I'm back!")
				}, 1000)
			}
			else{

				//Inject styles
				const linkElement = document.createElement("style");
		    linkElement.innerHTML = styles.trim();
		    document.getElementsByTagName("head")[0].appendChild(linkElement);

		    //Load clippy agent
		    clippyjs.load('Clippy', (clippy)=>{
		    	setClippy(clippy)
					setTimeout(()=>{
						clippy.show()
						clippy.speak("I'm back!")
					}, 1000)
		    })
		  }
	  }
	  else{
	  	if(clippy){
				clippy.stop();
				clippy.hide();	  	
			}
	  }
  }, [props.showClippy]);


	if(clippy){

		//Get message
		let message = null

		if(props.currentLayer !== oldProps.currentLayer){
			message = 'You selected the '+props.layers[props.currentLayer].name+' layer! That is one of my favourites!'
		}
		else if(props.showLayerPanel !== oldProps.showLayerPanel){
			if(!props.showLayerPanel)
				message = 'That side panel was in the way anyhow'
			else
				message = 'You can change the layer here!'
		}
		else if(props.currentPage !== oldProps.currentPage){
			var messages = {
				map:'It looks like you are trying to look at a map! You should try clicking on a ward to see some insights',
				data_view:'You appear to be looking at a grid of numbers',
				charts:'It looks like you are looking at some graphs. I prefer the map, but to each their own',
				export:'You can download a csv and import it into your favourite Microsoft spreadsheet sofware',
			}
			message = messages[props.currentPage];
		}
		else if(props.currentWard !== oldProps.currentWard){
			if(props.currentWard){
				let name = props.wards[props.currentWard].name; //error
				message = "Do you want to find out more about "+name+"?";	
			}
		}

		//Say it
		if(message){
			clippy.speak(message);		
		}

	}

	return null;
}

export default connect(

  (state, props)=>({
    currentLayer:state.currentLayer,
    currentPage:state.currentPage,
    showLayerPanel:state.showLayerPanel,
    layers:state.layers,
    currentWard:state.currentWard,
    wards:state.wards,
    showClippy:state.showClippy,
  }),
  (dispatch, props)=>({
  	
  })

)(Clippy);
