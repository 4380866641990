
import { ResponsiveBarCanvas } from '@nivo/bar'

import { useRef} from 'react'

import { connect } from 'react-redux'


import { traffic_light_color } from '../common.js'

function ChartsView(props)
{

	const fidGraph = useRef(null)
	const wwGraph = useRef(null)
	const imdGraph = useRef(null)



	function grabFid(e)
	{
		e.target.href = fidGraph.current.toDataURL('image/png');
	}

	function grabWw(e)
	{
		e.target.href = wwGraph.current.toDataURL('image/png');
	}

	function grabImd(e)
	{
		e.target.href = imdGraph.current.toDataURL('image/png');
	}


	let fidData = [];

	let wwData = [];

	for(var ward_code in props.wards){
		var ward_name = props.wards[ward_code].name
		var ward_data = props.wardData[ward_code]

		fidData.push({
			name:ward_name,

			'Pre-Covid':Math.round(ward_data.fid_2019),
			'Now':Math.round(ward_data.fid_2020),

			'IMD':Math.round(ward_data.imd_score),
		})

		wwData.push({
			name:ward_name,
			'Pre-Covid':Math.round(ward_data.ward_welfare_2019+1),
			'Now':Math.round(ward_data.ward_welfare_2020+1),
		})
	}

	const FidGraph = (
	    <ResponsiveBarCanvas

	    		ref={ fidGraph }

	        data={ fidData }
	        keys={[ 'Pre-Covid', 'Now' ]}
	        indexBy="name"
	        groupMode="grouped"
	        padding={0.2}
	        valueScale={{ type: 'linear' }}	        
	        margin={{ top: 30, right: 30, bottom: 120, left: 40 }}
	        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.5 ] ] }}
	        borderWidth={2}


	        minValue={0}
	        maxValue={35}

	        colors={[ '#0b05', '#0b0' ]}
	       
	       	enabledGridY={ true }
	       	gridYValues={[0,5,10,15,20,25,30,35]}

	       	theme={{
	        	fontSize:13,
	        	background:'#FFF'
	       	}}

	        axisTop={null}
	        axisRight={null}
					axisBottom={{
	        	tickRotation:45,
	        }}
	        axisLeft={{
						tickValues:[0,5,10,15,20,25,30,35]
	        }}
	        
	        legends={[
            {
              dataFrom: 'keys',
              anchor: 'bottom-left',
              direction: 'row',
              justify: false,
              translateX: 0,
              translateY: 100,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: 'left-to-right',
              itemOpacity: 0.85,
              symbolSize: 20,
              effects: [{
                on: 'hover',
                style: {
                  itemOpacity: 1
                }
              }]
            }
	        ]}
	    />
	)


const WwGraph = (
	    <ResponsiveBarCanvas


	    		ref={ wwGraph }

	        data={ wwData }
	        keys={[ 'Pre-Covid', 'Now' ]}
	        indexBy="name"
	        groupMode="grouped"
	        padding={0.2}
	        valueScale={{ type: 'linear' }}	        
	        margin={{ top: 30, right: 30, bottom: 120, left: 40 }}
	        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.5 ] ] }}
	        borderWidth={2}

	        minValue={0}
	        maxValue={3}

					colors={ (d)=>{
						/*
						if(d.id === 'Pre-Covid')return '#888'
						return traffic_light_color(d.value-1)
					*/
						return traffic_light_color(d.value-1) + ((d.id === 'Pre-Covid')?'5':'F');
					}}

	       
	       	enabledGridY={ true }
	       	//gridYValues={[0,1,2,3]}
	       	gridYValues={[]}

	       	theme={{
	        	fontSize:13,
	        	background:'#FFF'
	       	}}

	        axisTop={null}
	        axisRight={null}
					axisBottom={{
	        	tickRotation:45,
	        }}
	        axisLeft={{
						//tickValues:[0,1,2,3]
						tickValues:[]
	        }}

         legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-left',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: 100,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [{
              on: 'hover',
              style: {
                itemOpacity: 1
              }
            }]
          }
        ]}
	        
	    />
	)


	const ImdGraph = (
	    <ResponsiveBarCanvas


	    		ref={ imdGraph }

	        data={ fidData }
	        keys={[ 'IMD' ]}
	        indexBy="name"
	        groupMode="grouped"
	        padding={0.55}
	        valueScale={{ type: 'linear' }}	        
	        margin={{ top: 30, right: 30, bottom: 120, left: 40 }}

	        borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.5 ] ] }}
	        borderWidth={2}

	        minValue={0}
	        maxValue={50}

	        colors={[ '#66f' ]}
	       
	       	enabledGridY={ true }
	       	gridYValues={[0,10,20,30,40,50]}

	       	theme={{
	        	fontSize:13,
	        	background:'#FFF'
	       	}}

	        axisTop={null}
	        axisRight={null}
					axisBottom={{
	        	tickRotation:45,
	        }}
	        axisLeft={{
						tickValues:[0,10,20,30,40,50]
	        }}
	        
	    />
	)



	return (
		<div className="content">
			<div className="chart-view">
				<h1>Charts</h1>


				<h2>Food Insecurity Detected
					<button data-html="true" data-tip={"<div>"+props.layers['fid'].tooltip+"</div>"} data-for="layer-tip" className="info-button">i</button>

					<a 
						className="download-button" 
						onClick={ grabFid }
						download="graph.png" 
						data-html={true} 
						data-tip={ "<div>Download this graph</div>" } 
						data-for={"layer-tip"} 
					>Download</a>


				</h2>
				<div style={{ height: "350px" }}>
					{ FidGraph }
		    </div>

				<h2>Ward Welfare
					<button data-html="true" data-tip={"<div>"+props.layers['ward_welfare'].tooltip+"</div>"} data-for="layer-tip" className="info-button">i</button>
					<a 

						className="download-button" 
						onClick={ grabWw }
						download="graph.png" 
						data-html={true} 
						data-tip={ "<div>Download this graph</div>" } 
						data-for={"layer-tip"} 
					>Download</a>
				</h2>
				<div style={{ height: "350px" }}>
					{ WwGraph }
				</div>

				<h2>Index of Multiple Deprivation
					<button data-html="true" data-tip={"<div>"+props.layers['imd_score_rank'].tooltip+"</div>"} data-for="layer-tip" className="info-button">i</button>
					<a 
						className="download-button" 
						onClick={ grabImd }
						download="graph.png" 
						data-html={true} 
						data-tip={ "<div>Download this graph</div>" } 
						data-for={"layer-tip"} 
					>Download</a>
				</h2>
				<div style={{ height: "350px" }}>
					{ ImdGraph }
				</div>

			</div>
		</div>
	)
}



export default connect(


  (state, props)=>({
    currentWard:state.currentWard,
		wardData: state.wardData,
		wards:state.wards,
		layers:state.layers,
  }),

  (dispatch, props)=>({

  })

)(ChartsView);