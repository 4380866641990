
import { motion, AnimateSharedLayout } from 'framer-motion'

import { fastTransition } from '../common.js'

function Rocker({options, value, onChange})
{
	return (
		<AnimateSharedLayout>
			<div className="rocker" >
				{options.map( option=>(
					<button key={option.value} onClick={ (e)=>{onChange(option.value);e.stopPropagation()} } className={ option.value === value ? 'rocker-selected ': '' }>
						<div className="rocker-background" />
						{ option.value === value && <motion.div layout layoutId="selected-item" className="rocker-selected-background" transition={ fastTransition }/> }
						<motion.div className="rocker-content" animate={{ color:(option.value === value ? '#FFF' : '#000') }} >{ option.label }</motion.div>
					</button>
				))}
			</div>
		</AnimateSharedLayout>
	)

	/*	
	//This is the boring version
	return (
		<div className="rocker" >
			{Object.entries(options).map( ([value2, text])=>(
				<button onClick={ ()=>onChange(value2) } className={ value2 == value ? 'rocker-selected ': '' }>{ text }</button>
			))}
		</div>
	)*/
}

export default Rocker