

const traffic_light_colors = {
	red:'#F44',
	amber:'#FB0',
	green:'#4A4',
}
exports.traffic_light_colors = traffic_light_colors

const traffic_light_labels = {
	red:'high concern',
	amber:'some concern',
	green:'low concern',
}
exports.traffic_light_labels = traffic_light_labels

function traffic_light_name(value){
	if(value < 0.5)return 'green'
	if(value > 1.5)return 'red'
	return 'amber'
	/*
	if(value < 6)return 'green'
	if(value > 7)return 'red'
	return 'amber'
	*/
}
exports.traffic_light_name = traffic_light_name

exports.traffic_light_color = function(value){
	return traffic_light_colors[traffic_light_name(value)]
}

exports.traffic_light_label = function(value){
	return traffic_light_labels[traffic_light_name(value)]
}

exports.fastTransition = { type: "spring", damping:30, stiffness:200, mass: 1 }
