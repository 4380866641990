
import { connect } from "react-redux";

import { motion } from "framer-motion"


//export default MenuTab;

function MenuTab(props) {
	return (
		<button className={props.isSelected?"tab tab-selected":"tab"} onClick={ props.select }>

			{props.children}

			{ props.isSelected && (
				<motion.div layoutId="menu-tab-underline" className="menu-tab-underline" />
			) }
		</button>
	)
}




export default connect(

  (state, props)=>({
    isSelected: state.currentPage === props.page,
  }),

  (dispatch, props)=>({
    select: ()=>{
      dispatch({type:'SET_PAGE', payload:{page:props.page}})
    },
  })

)(MenuTab);